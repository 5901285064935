import CoursesIcon from "../icons/Courses";
import ConferencesIcon from "../icons/Conferences";
import BooksIcon from "../icons/Books";
import type { ActivityTypeData } from "../types";
import TrendingIcon from "../icons/Trending";
import PublicationsIcon from "../icons/Publications";
import InternalTrainingIcon from "../icons/InternalTraining";
import ToolsIcon from "../icons/Tools";

type ActivityTypeMap = {
  [key: string]: ActivityTypeData;
};

export const ROOT_PATH = "/";
export const API_PATH = "/api/v1";
export const PARTNER_HUB_PATH = `${ROOT_PATH}partner-hub`;
export const PATHWAYS_PATH = `${ROOT_PATH}pathways`;
export const DASHBOARD_PATH = "/dashboards";
export const MY_ACTIVITY_PATH = `${DASHBOARD_PATH}/my-activity`;
export const TEAM_PATH = `${DASHBOARD_PATH}/team`;
export const MY_TEAM_PATH = `${TEAM_PATH}/my-team`;
export const APPROVAL_PATH = `${DASHBOARD_PATH}/approval`;
export const COMPANY_PATH = `${DASHBOARD_PATH}/company`;
export const RESOURCES_PATH = `${DASHBOARD_PATH}/resources`;
export const EDIT_PATHWAYS_PATH = `${PATHWAYS_PATH}/:id/edit`;
export const MY_COMPANY_PATH = `${COMPANY_PATH}/my-company`;
export const API_PACKETS_PATH = `${API_PATH}/packets`;

export const ACTIVITY_TYPES: ActivityTypeMap = {
  "Internal Training": {
    path: `${ROOT_PATH}internal-trainings`,
    label: "Internal Training",
    color: "text-secondary",
    Icon: InternalTrainingIcon,
    description: "Internal training from your employer",
    buttonStyle: "secondaryContained",
  },
  Course: {
    path: `${ROOT_PATH}courses`,
    label: "Courses",
    color: "text-pink",
    Icon: CoursesIcon,
    description: "From online to in-person classes",
    buttonStyle: "pinkContained",
  },
  Conference: {
    path: `${ROOT_PATH}conferences`,
    label: "Conferences",
    color: "text-purple",
    Icon: ConferencesIcon,
    description: "The biggest conferences in the nation",
    buttonStyle: "purpleContained",
    toggle: true,
    toggleValueOne: "Future",
    toggleValueTwo: "Past",
  },
  Book: {
    path: `${ROOT_PATH}books`,
    label: "Books",
    color: "text-blue",
    Icon: BooksIcon,
    description: "Read up on the best material available",
    buttonStyle: "blueContained",
  },
  Publication: {
    path: `${ROOT_PATH}publications`,
    label: "Publications",
    color: "text-primary",
    Icon: PublicationsIcon,
    description: "Stay up to date through leading content providers",
    buttonStyle: "primaryContained",
  },
  Tool: {
    path: `${ROOT_PATH}tools`,
    label: "Tools",
    color: "text-orange",
    Icon: ToolsIcon,
    description: "Find the best one for the job",
    buttonStyle: "primaryContained",
  },
};

export const TRENDING_ACTIVITY: ActivityTypeData = {
  path: `${ROOT_PATH}trending_activities`,
  label: "Trending",
  color: "text-secondaryBlue",
  Icon: TrendingIcon,
  description: "Trending activities on OneRange",
  buttonStyle: "secondaryBlueContained",
  toggle: true,
  toggleValueOne: "Global",
  toggleValueTwo: "Company",
};
