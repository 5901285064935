import * as React from "react";
import { createCtx } from "../../utils";
import type {
  Activity,
  ActivityTypeData,
  CurrentUser,
  EmployeeSubordinate,
  Pack,
  VirtualCard,
  TopLearner,
  Employee,
  Budget,
  Department,
  Company,
  Page,
} from "../../types";
import { parseDashboardInitialData } from "./utils";
import LogoIcon from "../../icons/LogoIcon";

export interface StoreState {
  currentUser: CurrentUser;
  isInternalTrainingActive: boolean;
  companySettings: {
    manualReimbursementEnabled: boolean;
  };
  isGlobalTrendingActive: boolean;
  companyPolicyUrl: string;
  activityTypeData: {
    [id: string]: ActivityTypeData & { key: string; hidden: boolean };
  };
  categoryData: {
    [id: string]: {
      label: string;
      Icon: React.ReactNode;
      subcategories: string[];
    };
  };
  packs: Pack[];
  cards: VirtualCard[];
  wishlist: Array<Activity & { wishlistId: string }>;
  wishlistMap: Map<string, string>; // Map in the shape of Map<activityId, wishlistId>
  teamEmployees: {
    hasFetched: boolean;
    data: EmployeeSubordinate[];
  };
  employeeDepartments: {
    hasFetched: boolean;
    data: Department[];
  };
  employeeCompanies: {
    hasFetched: boolean;
    data: Company[];
  };
  groupOption: string;
  dashboard: {
    budgets: Budget[];
    totalAvailable: number;
    totalAllowance: number;
    totalUsed: number;
    endDate: string;
    activeRequests: number;
    approvedRequests: number;
    topLearners: TopLearner[];
    hasDepartment: boolean;
    sharedBudget: boolean;
    hiddenIndividualBudget: boolean;
    manager: Employee | null;
  };
  filterTab: string;
  features: {
    resource_tracking: {
      enabled: boolean;
    };
    edit_pathway: {
      enabled: boolean;
    };
  };
}

type StoreDispatch = React.Dispatch<React.SetStateAction<StoreState>>;

const [useStore, StoreContextProvider] = createCtx<{
  state: StoreState;
  dispatch: StoreDispatch;
}>();

const StoreProvider: React.FC<{
  initialData: any;
  type: Page;
}> = ({ children, initialData, type }) => {
  const [state, dispatch] = React.useState<StoreState>(() => {
    const {
      currentUser,
      isInternalTrainingActive,
      companySettings,
      isGlobalTrendingActive,
      companyPolicyUrl,
      activityTypeData,
      categoryData,
      dashboard,
      packs,
      cards,
      wishlist,
      wishlistMap,
      filterTab,
      features,
    } = parseDashboardInitialData(initialData, type);

    return {
      currentUser,
      isInternalTrainingActive,
      companySettings,
      isGlobalTrendingActive,
      companyPolicyUrl,
      activityTypeData,
      wishlist,
      wishlistMap,
      cards,
      categoryData,
      packs,
      teamEmployees: {
        hasFetched: false,
        data: [],
      },
      employeeDepartments: {
        hasFetched: false,
        data: [],
      },
      employeeCompanies: {
        hasFetched: false,
        data: [],
      },
      groupOption: "team",
      dashboard,
      filterTab,
      features,
    };
  });

  return (
    <StoreContextProvider value={{ state, dispatch }}>
      {children}
    </StoreContextProvider>
  );
};

const updateWishlist = (
  {
    wishlist,
    wishlistMap,
  }: {
    wishlist: StoreState["wishlist"];
    wishlistMap: StoreState["wishlistMap"];
  },
  dispatch: StoreDispatch,
) => {
  dispatch((current) => ({
    ...current,
    wishlist,
    wishlistMap,
  }));
};

const updateFilterTab = (
  {
    filterTab,
  }: {
    filterTab: StoreState["filterTab"];
  },
  dispatch: StoreDispatch,
) => {
  dispatch((current) => ({
    ...current,
    filterTab,
  }));
};

const useActivityTypeData = (activityTypeId: string) => {
  const {
    state: { activityTypeData },
  } = useStore();

  if (!activityTypeData[activityTypeId]) {
    throw new Error("Activity type not found");
  }
  return activityTypeData[activityTypeId];
};

const useCategoryData = (categoryId: string) => {
  const {
    state: { categoryData },
  } = useStore();

  if (!categoryData[categoryId]) {
    return { label: "", Icon: LogoIcon };
  }
  return categoryData[categoryId];
};

export {
  useStore,
  StoreProvider,
  useActivityTypeData,
  updateWishlist,
  updateFilterTab,
  useCategoryData,
};
