import * as React from "react";
import Button, { ButtonVariants } from ".";
import ChevronRightIcon from "../../icons/ChevronRight";
import LoadingIcon from "../LoadingIcon";

interface SubmitButtonProps {
  onClick?: (event: React.MouseEvent) => void;
  disabled?: boolean;
  isSubmitting?: boolean;
  size?: "small" | "normal" | "large" | "huge";
  fullWidth?: boolean;
  className?: string;
  type?: "button" | "submit" | "reset";
  variant?: ButtonVariants;
}

const iconSizeMap = {
  small: 14,
  normal: 16,
  large: 20,
  huge: 24,
};

const SubmitButton: React.FC<SubmitButtonProps> = ({
  onClick,
  disabled,
  isSubmitting,
  children,
  size = "large",
  type = "submit",
  fullWidth,
  className,
  variant = "secondaryContained",
}) => {
  return (
    <Button
      onClick={onClick}
      variant={variant}
      type={type}
      size={size}
      disabled={disabled || isSubmitting}
      fullWidth={fullWidth}
      className={className}
    >
      <span>{children}</span>
      <span className="flex w-7 items-center justify-end">
        {isSubmitting ? (
          <LoadingIcon size="tiny" color="white" />
        ) : (
          <ChevronRightIcon size={iconSizeMap[size]} />
        )}
      </span>
    </Button>
  );
};

export default SubmitButton;
