import * as React from "react";
import * as ReactDOM from "react-dom";
import SignInPage from "../range_react/pages/SignInPage";
import "./range_react.scss";
import "../helpers/SentryInit";

document.addEventListener("DOMContentLoaded", () => {
  ReactDOM.render(
    <SignInPage />,
    document.body.appendChild(document.createElement("div")),
  );
});
