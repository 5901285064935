import * as React from "react";

const Compliance: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        d="M12.0827 15V19L10 18L7.91602 19V15.0002"
        stroke="#050F4A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 15.5C11.6569 15.5 13 14.1569 13 12.5C13 10.8431 11.6569 9.5 10 9.5C8.34315 9.5 7 10.8431 7 12.5C7 14.1569 8.34315 15.5 10 15.5Z"
        stroke="#050F4A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 16.6667H15.8333C16.2754 16.6667 16.6993 16.4911 17.0118 16.1786C17.3244 15.866 17.5 15.4421 17.5 15V5.00004C17.5 4.55801 17.3244 4.13409 17.0118 3.82153C16.6993 3.50897 16.2754 3.33337 15.8333 3.33337H4.16667C3.72464 3.33337 3.30072 3.50897 2.98816 3.82153C2.67559 4.13409 2.5 4.55801 2.5 5.00004V15C2.5 15.4421 2.67559 15.866 2.98816 16.1786C3.30072 16.4911 3.72464 16.6667 4.16667 16.6667H5"
        stroke="#050F4A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1673 6.66663H5.83398"
        stroke="#050F4A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Compliance;
