import * as React from "react";

const Courses: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.70316 4.09499L3.52916 7.52499C2.23116 8.24599 2.23116 10.112 3.52916 10.833L9.70316 14.263C11.1312 15.056 12.8682 15.056 14.2972 14.263L20.4712 10.833C21.7692 10.112 21.7692 8.24599 20.4712 7.52499L14.2972 4.09499C12.8682 3.30199 11.1322 3.30199 9.70316 4.09499Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.99121 12.2V16.124C5.99121 17.073 6.46621 17.958 7.25521 18.483L8.86221 19.552C10.7632 20.816 13.2372 20.816 15.1372 19.552L16.7442 18.483C17.5342 17.958 18.0082 17.072 18.0082 16.124V12.2"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Courses;
