import * as React from "react";

const MIN_SCREEN_WIDTH = 1024; // TO DO - make the app responsive

const AppWrapper: React.FC = ({ children }) => {
  return (
    <div
      style={{ minWidth: MIN_SCREEN_WIDTH }}
      className="text-primary overflow-hidden"
    >
      {children}
    </div>
  );
};

export default AppWrapper;
