import * as React from "react";
import * as superagent from "superagent";
import get from "lodash/get";
import { format, parseISO } from "date-fns";
import { useStore } from ".";
import type { StoreState } from ".";
import { ACTIVITY_TYPES, API_PATH, TRENDING_ACTIVITY } from "../../constants";
import LogoIcon from "../../icons/LogoIcon";
import SalesAndMarketingIcon from "../../icons/SalesAndMarketing";
import DesignIcon from "../../icons/Design";
import DataScienceIcon from "../../icons/DataScience";
import ProgrammingIcon from "../../icons/Programming";
import PersonalDevelopmentIcon from "../../icons/PersonalDevelopment";
import ComplianceIcon from "../../icons/Compliance";
import ITIcon from "../../icons/IT";
import BusinessIcon from "../../icons/Business";
import type {
  Activity,
  CurrentUser,
  Employee,
  Pack,
  TopLearner,
  VirtualCard,
  Budget,
  Page,
} from "../../types";
import {
  parseServerActivity,
  parseServerPack,
  parseErrorMessage,
  parseServerEmployee,
  convertPriceToCents,
  parseTrendingServerData,
  parsePackServerData,
} from "../../utils";
import { useSnackbar } from "../SnackbarContext";
import OfficeProductivityIcon from "../../icons/OfficeProductivity";
import { useLocation } from "react-router-dom";

export function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function queryParamsNoHook() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams;
}

export function parseQuizData(initialData: any): any {
  let questions = [];

  initialData.included.forEach((data) => {
    let answers = [];
    if (data.type === "quiz_question") {
      initialData.included.forEach((answerData) => {
        if (
          answerData.type === "quiz_answer" &&
          answerData.relationships.quiz_question.data.id == data.attributes.id
        ) {
          answers.push({
            id: answerData.attributes.id,
            answerText: answerData.attributes.answer_text,
            type: null,
            position: answerData.attributes.position,
          });
        }
      });
      questions.push({
        id: data.attributes.id,
        position: data.attributes.position,
        questionText: data.attributes.question_text,
        multiAnswer: data.attributes.multi_answer,
        answers: answers,
      });
    }
  });
  return questions;
}

const initialActivityTypeObject = {};

export function parseActivityTypeData(
  initialData: any,
): StoreState["activityTypeData"] {
  return Object.entries(ACTIVITY_TYPES).reduce<StoreState["activityTypeData"]>(
    (a, [key, activityTypeData]) => {
      initialData.activity_types.data.forEach(
        (activity_type: {
          attributes: { name: string; id: string | number; hidden: boolean };
        }) => {
          if (activity_type.attributes.name === key) {
            a[activity_type.attributes.id] = {
              ...activityTypeData,
              key: key,
              hidden: activity_type.attributes.hidden,
            };
          }
        },
      );
      return a;
    },
    initialActivityTypeObject,
  );
}

const categoryIconMap: { [key: string]: React.ReactNode } = {
  Design: <DesignIcon />,
  "Data Science": <DataScienceIcon />,
  "Sales & Marketing": <SalesAndMarketingIcon />,
  "Software Development": <ProgrammingIcon />,
  Business: <BusinessIcon />,
  "Personal Development": <PersonalDevelopmentIcon />,
  "Office Productivity": <OfficeProductivityIcon />,
  IT: <ITIcon />,
  Compliance: <ComplianceIcon />,
};

export function budgetParser(budgets: any): Budget[] {
  let parsedBudget = [];
  budgets.forEach((budget) => {
    parsedBudget.push({
      id: budget.id,
      endDate: budget
        ? format(parseISO(budget.attributes.ended_at), "MMM dd, yyyy")
        : "",
      startDate: budget
        ? format(parseISO(budget.attributes.started_at), "MMM dd, yyyy")
        : "",
      totalAllocated: budget?.attributes.total_allocated.cents || 0,
      totalAllowance: budget?.attributes.total_allowance.cents || 0,
      totalAvailable: budget?.attributes.total_available.cents || 0,
      isUnlimited: budget?.attributes.unlimited,
      isShared: budget?.attributes.shared,
      isHidden: budget?.attributes.hidden,
    });
  });

  return parsedBudget;
}

export function parseCategoryData(initialData: {
  categories: { data: any[] };
}): StoreState["categoryData"] {
  return initialData.categories.data.reduce<StoreState["categoryData"]>(
    (a, { id, attributes: { name, subcategories } }) => {
      a[id] = {
        label: name,
        Icon: categoryIconMap[name] || <LogoIcon size={16} />,
        subcategories: subcategories,
      };
      return a;
    },
    {},
  );
}

export function parsePackData(initialData: {
  pre_approvals: { data: any[]; included: any[] };
}): StoreState["packs"] {
  if (initialData.pre_approvals.included) {
    const activityMap = initialData.pre_approvals.included.reduce<{
      [id: string]: Activity;
    }>((a, b) => {
      const activity = parseServerActivity(b);
      a[activity.id] = activity;
      return a;
    }, {});
    return initialData.pre_approvals.data.map((serverPack) =>
      parseServerPack(serverPack, activityMap),
    );
  } else {
    return [];
  }
}

export function parseCardData(cards: any): VirtualCard[] {
  const virtualCards: VirtualCard[] = [];

  cards.forEach((card) => {
    const cardAttributes = card.attributes;
    if (cardAttributes.enabled && cardAttributes.company_enabled) {
      virtualCards.push({
        last4: cardAttributes.last4,
        cardId: cardAttributes.id,
        externalCardId: cardAttributes.external_card_id,
        companyName: cardAttributes.company_name,
        billingAddress: cardAttributes.billing_address,
        stripeAccount: cardAttributes.stripe_account,
      });
    }
  });

  return virtualCards;
}

export function checkIfRoleExistsInDashboardUser(
  roleData: any[],
  roleName: string,
): boolean {
  return Boolean(roleData.find(({ name }) => name === roleName));
}

export function parseDashboardInitialData(
  initialData: any,
  type: Page,
): {
  currentUser: CurrentUser;
  isInternalTrainingActive: boolean;
  companySettings: { manualReimbursementEnabled: boolean };
  isGlobalTrendingActive: boolean;
  companyPolicyUrl: string;
  activityTypeData: StoreState["activityTypeData"];
  categoryData: StoreState["categoryData"];
  dashboard: StoreState["dashboard"];
  packs: StoreState["packs"];
  cards: StoreState["cards"];
  wishlist: StoreState["wishlist"];
  wishlistMap: StoreState["wishlistMap"];
  filterTab: StoreState["filterTab"];
  features: {
    resource_tracking: { enabled: boolean };
    edit_pathway: { enabled: boolean };
  };
} {
  let budget;

  if (initialData.employee.included) {
    budget = (initialData.employee.included as any[]).find(
      ({ type }) => type === "budget",
    );
  }

  let budgets;

  if (initialData.employee.data) {
    budgets = (initialData.employee.included as any[]).filter(
      ({ type }) => type === "budget",
    );

    budgets = budgetParser(budgets);
  } else {
    budgets = null;
  }

  const wishlist: Array<Activity & { wishlistId: string }> = [];
  const wishlistMap: Map<string, string> = new Map();
  const employeeMap: Record<string, Employee> = {};
  const topLearners: TopLearner[] = [];
  const virtualCards: VirtualCard[] = [];

  if (type === "dashboard") {
    initialData.top_employees.included.forEach((item: any) => {
      if (item.type === "employee") {
        employeeMap[item.id] = parseServerEmployee(item);
      }
    });
    initialData.top_employees.data.forEach((item: any) => {
      const topLearner: TopLearner = {
        percentBudgetUsed:
          (100 * item.attributes.total_allocated.cents) /
          item.attributes.total_allowance.cents,
        totalRequests: item.relationships.requests.data.length,
        ...employeeMap[item.relationships.employee.data.id],
      };
      topLearners.push(topLearner);
    });
  }

  const activityMap: Map<string, Activity> = new Map();
  const wishListSkills = [];
  const pricingOptions = [];

  Object.keys(initialData.resources || []).forEach((resourceType) => {
    const includedItems = initialData.resources[resourceType].included;
    const includedSkills = includedItems.filter(
      (includedItem) => includedItem.type === "skill",
    );
    wishListSkills.push(...includedSkills);
  });

  const { included } = initialData.employee;
  const includedCards = included?.filter((i) => i.type === "card");
  const includedActivities = included?.filter((i) => i.type === "activity");
  const includedWishlistItems = included?.filter(
    (i) => i.type === "wishlist_item",
  );

  includedActivities.forEach((item: any) => {
    const activity = parseServerActivity(item, pricingOptions, wishListSkills);
    activityMap.set(item.id, activity);
  });

  includedWishlistItems.forEach((item: any) => {
    const wishlistId = item.id;
    const activityId = `${item.attributes.activity_id}`;
    wishlistMap.set(activityId, wishlistId);

    const activity = activityMap.get(activityId);
    if (activity) {
      wishlist.push({ ...activity, wishlistId });
    } else {
      wishlist.push({ id: activityId, wishlistId } as any);
    }
  });

  includedCards.forEach((item: any) => {
    virtualCards.push(item);
  });

  const cards = parseCardData(virtualCards);

  const managerId = get(
    initialData,
    "employee.data.relationships.manager.data.id",
  );

  let manager: Employee | null = null;
  if (initialData.employee.included) {
    initialData.employee.included.forEach((item: any) => {
      if (item.type === "employee" && item.id === managerId) {
        manager = parseServerEmployee(item);
      }
    });
  }

  function getDashboardHeaderValues(): {
    totalAvailable: number;
    totalAllowance: number;
    totalUsed: number;
    sharedBudget: boolean;
    hiddenIndividualBudget: boolean;
  } {
    if (type === "marketplace" || type === "resources") {
      return {
        totalAvailable: 0,
        totalAllowance: 0,
        totalUsed: 0,
        sharedBudget: false,
        hiddenIndividualBudget: false,
      };
    }
    if (type === "dashboard") {
      return {
        totalAvailable: budget?.attributes.total_available.cents || 0,
        totalAllowance: budget?.attributes.total_allowance.cents || 0,
        totalUsed: 0,
        sharedBudget: false,
        hiddenIndividualBudget: false,
      };
    }
    const totalAllowance =
      convertPriceToCents(initialData.available_budget) || 0;
    const amountUsed = convertPriceToCents(initialData.total_budget) || 0;
    return {
      totalAvailable: totalAllowance - amountUsed,
      totalAllowance,
      totalUsed: amountUsed,
      sharedBudget: initialData.shared_budget,
      hiddenIndividualBudget: initialData.hidden_individual_budget,
    };
  }

  return {
    currentUser: initialData.employee.data
      ? {
          firstName: initialData.employee.data.attributes.first_name,
          lastName: initialData.employee.data.attributes.last_name,
          id: initialData.employee.data.id,
          availableBudget:
            initialData.employee.data.attributes.available_budget,
          totalBudget: initialData.employee.data.attributes.total_allocated,
          budgetShared: initialData.employee.data.attributes.shared,
          hideIndividualBudget:
            initialData.employee.data.attributes.hide_individual_budget,
          budgetYears: initialData.employee.data.attributes.budget_years,
          phone: initialData.employee.data.attributes.phone,
          pronouns: initialData.employee.data.attributes.pronouns,
          email: initialData.employee.data.attributes.personal_email,
          workEmail: initialData.employee.data.attributes.work_email,
          impersonatedUser:
            initialData.employee.data.attributes.impersonated_user,
          completedActivities:
            initialData.employee.data.attributes.completed_activities,
          imageUrl: initialData.employee.data.attributes.image_url,
          showCardholderAgreement:
            initialData.employee.data.attributes.show_cardholder_agreement,
          isInternalTrainingAdmin: checkIfRoleExistsInDashboardUser(
            initialData.employee.data.attributes.all_roles,
            "internal_training_admin",
          ),
          isAdmin: checkIfRoleExistsInDashboardUser(
            initialData.employee.data.attributes.all_roles,
            "admin",
          ),
          isPacketManager: checkIfRoleExistsInDashboardUser(
            initialData.employee.data.attributes.all_roles,
            "packet_manager",
          ),
          isAdditionalApprover: checkIfRoleExistsInDashboardUser(
            initialData.employee.data.attributes.all_roles,
            "additional_approver",
          ),
          isSiteAdmin: checkIfRoleExistsInDashboardUser(
            initialData.employee.data.attributes.all_roles,
            "site_admin",
          ),
          isRestricted: checkIfRoleExistsInDashboardUser(
            initialData.employee.data.attributes.all_roles,
            "restricted",
          ),
          isReadOnlyAdmin: checkIfRoleExistsInDashboardUser(
            initialData.employee.data.attributes.all_roles,
            "read_only_admin",
          ),
          isManager: initialData.employee.data.attributes["manager?"],
          companyId: initialData.employee.data.attributes.company_id,
        }
      : {
          firstName: "",
          lastName: "",
          id: "",
          availableBudget: "",
          totalBudget: "",
          budgetShared: false,
          hideIndividualBudget: false,
          budgetYears: [],
          phone: "",
          pronouns: "",
          email: "",
          workEmail: "",
          impersonatedUser: "",
          imageUrl: "",
          showCardholderAgreement: false,
          isInternalTrainingAdmin: false,
          isAdmin: false,
          isSiteAdmin: false,
          isManager: false,
          isPacketManager: false,
          isAdditionalApprover: false,
          isRestricted: false,
          isReadOnlyAdmin: false,
          completedActivities: [],
          companyId: "",
        },
    isInternalTrainingActive: initialData.internal_training_active,
    companySettings: initialData.company_settings.data.attributes,
    isGlobalTrendingActive: initialData.global_trending_active,
    companyPolicyUrl: initialData.company_policy_url,
    packs: parsePackData(initialData),
    categoryData: parseCategoryData(initialData),
    activityTypeData: parseActivityTypeData(initialData),
    dashboard: {
      budgets: budgets,
      endDate: initialData.budget_end_date
        ? format(parseISO(initialData.budget_end_date), "MMM dd, yyyy")
        : "",
      activeRequests: initialData.active_requests_count,
      approvedRequests: initialData.approved_requests_count,
      topLearners,
      hasDepartment:
        (get(initialData, "employee.data.relationships.departments.data") || [])
          .length > 0,
      manager,
      ...getDashboardHeaderValues(),
    },
    cards,
    wishlist,
    wishlistMap,
    filterTab: "Global",
    features: initialData.features,
  };
}

function parseServerPackData(initialData: any): Pack[] {
  const activityMap: Record<string, Activity> = {};
  (initialData.included as any[]).forEach((item) => {
    if (item.type === "activity") {
      activityMap[item.id] = parseServerActivity(item);
    }
  });
  const packs: Pack[] = [];
  (initialData.data as any[]).forEach((item) => {
    packs.push({
      name: item.attributes.name,
      employeeId: `${item.attributes.employee_id}`,
      description: item.attributes.description,
      id: item.id,
      visibility: item.attributes.visibility,
      creator: item.attributes.creator,
      activities: (item.relationships.activities.data as any[]).map(
        ({ id }) => activityMap[id],
      ),
    });
  });
  return packs;
}

export async function getTrendingData(baseUrl) {
  const { body } = await superagent.get(baseUrl);
  const { items: trendingItems, paginationData } =
    await parseTrendingServerData(body);
  const carouselHeaderProps = TRENDING_ACTIVITY;
  const trendingData = {
    carouselHeaderProps,
    trendingItems,
    initialPaginationData: paginationData,
  };
  return trendingData;
}

export async function getPackData(baseUrl) {
  const { body } = await superagent.get(baseUrl);
  const { items: packs, paginationData } = await parsePackServerData(body);

  const packData = {
    packs,
    initialPaginationData: paginationData,
  };
  return packData;
}

export function useSetPacks() {
  const {
    dispatch,
    state: {
      currentUser: { isManager },
    },
  } = useStore();
  const { setSnackbarMessage } = useSnackbar();

  const fetcher = React.useCallback(async () => {
    try {
      const { body } = await superagent.get(`${API_PATH}/packets`);
      const packs = parseServerPackData(body);
      dispatch((current) => {
        return {
          ...current,
          packs,
        };
      });
    } catch (error) {
      setSnackbarMessage(parseErrorMessage(error), "error");
    }
  }, [dispatch, setSnackbarMessage]);

  React.useEffect(() => {
    if (isManager) {
      fetcher();
    }
  }, [fetcher, isManager]);
}
