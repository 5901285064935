import * as React from "react";
import Logo from "../components/Logo";
import SubmitButton from "../components/Button/SubmitButton";
import Link from "../components/Link";
import OnboardingPageContainer from "../components/Onboarding/OnboardingPageContainer";
import { formContainerStyles } from "../constants/styles";
import OnboardingHeader from "../components/Onboarding/OnboardingHeader";
import OnboardingDescription from "../components/Onboarding/OnboardingDescription";
import GoogleIcon from "../icons/Google";
import MicrosoftIcon from "../icons/Microsoft";
import { getCsrfToken } from "../utils";
import { getInputStyles } from "../components/Form/utils";
import FormLabel from "../components/Form/FormLabel";
import AppWrapper from "../components/AppWrapper";
import EyeSlashIcon from "../icons/EyeSlash";
import EyeIcon from "../icons/Eye";
import CloseIcon from "../icons/Close";

const csrfToken = getCsrfToken();

const getFlashMessage = (): string | undefined => {
  const node: any = document.getElementById("flash_data");
  const flashData = node && JSON.parse(node.getAttribute("data"));
  if (flashData && flashData[0] && flashData[0][1]) {
    return flashData[0][1];
  }
  return undefined;
};

const SignInPage: React.FC = () => {
  const [flashMessage, setFlashMessage] = React.useState<string | undefined>(
    () => getFlashMessage(),
  );
  const [passwordType, setPasswordType] = React.useState<"text" | "password">(
    "password",
  );

  const togglePasswordType = () => {
    setPasswordType((current) =>
      current === "password" ? "text" : "password",
    );
  };

  return (
    <AppWrapper>
      <OnboardingPageContainer>
        <div className="mb-8">
          <Logo />
        </div>
        <OnboardingHeader>Welcome!</OnboardingHeader>
        <div className="mt-2 mb-8">
          <a
            href="/users/auth/google_oauth2"
            className="inline-flex items-center bg-google text-white border-2 rounded border-google transition-colors hover:bg-google-alt hover:border-google-alt"
          >
            <span className="bg-white p-2 rounded">
              <GoogleIcon />
            </span>
            <span className="font-bold px-3">Sign in with Google</span>
          </a>
          <a
            href="/users/auth/microsoft_office365"
            className="inline-flex items-center text-microsoft border-2 border-microsoft-alt transition-colors hover:text-white hover:bg-microsoft-alt hover:border-microsoft-alt ml-4"
          >
            <span className="p-2 rounded">
              <MicrosoftIcon />
            </span>
            <span className="font-bold pr-3">Sign in with Microsoft</span>
          </a>
        </div>
        <OnboardingDescription>
          Or sign in with your email and password
        </OnboardingDescription>
        <form
          className={formContainerStyles}
          method="post"
          id="login"
          action="/users/sign_in"
        >
          <div>
            <input type="hidden" value={csrfToken} name="authenticity_token" />
            {flashMessage && (
              <div className="bg-secondary-light text-primary border border-secondary rounded px-3 py-2 mb-6 flex items-center justify-between">
                <span>{flashMessage}</span>
                <button
                  onClick={() => setFlashMessage(undefined)}
                  className="flex"
                  type="button"
                >
                  <CloseIcon />
                </button>
              </div>
            )}
            <div className="grid grid-cols-2 gap-4">
              <div>
                <FormLabel name="user[email]" label="Email" />
                <input
                  name="user[email]"
                  className={getInputStyles(false)}
                  type="email"
                  id="email"
                />
              </div>
              <div>
                <div>
                  <FormLabel name="user[password]" label="Password" />
                  <div className="relative">
                    <input
                      name="user[password]"
                      className={`${getInputStyles(false)} pr-10`}
                      type={passwordType}
                      id="password"
                    />
                    <div className="absolute right-2 bottom-0 top-0 flex items-center">
                      <button
                        type="button"
                        className="flex items-center justify-center text-secondary hover:text-secondary-alt outline-none rounded-full"
                        onClick={togglePasswordType}
                      >
                        {passwordType === "password" ? (
                          <EyeSlashIcon />
                        ) : (
                          <EyeIcon />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="block text-right text-sm mt-4">
                  <Link href="/users/password/new">Forgot password</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end mt-4">
            <div>
              <SubmitButton>Sign in</SubmitButton>
            </div>
          </div>
        </form>
      </OnboardingPageContainer>
    </AppWrapper>
  );
};

export default SignInPage;
