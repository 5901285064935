import * as React from "react";
import * as superagent from "superagent";
import { format, parseISO, formatISO, parse, differenceInDays } from "date-fns";
import type {
  Activity,
  ActivityPlatform,
  ActivityTracker,
  Company,
  Department,
  Employee,
  EmployeeSubordinate,
  Pack,
  PaginationData,
  Platform,
  Reimbursement,
  PricingOption,
  ResourceRequest,
  ShowEmployee,
  Skill,
} from "../types";
import { budgetParser } from "../components/StoreContext/utils";

export function createCtx<A extends {} | null>() {
  const ctx = React.createContext<A | undefined>(undefined);
  function useCtx() {
    const c = React.useContext(ctx);
    if (c === undefined)
      throw new Error(
        "This context hook must be wrapped in a provider with a value",
      );
    return c;
  }
  return [useCtx, ctx.Provider] as const;
}

export const numberWithDelimiters = (number: number) =>
  number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const pluralize = (number: number, description: string) => {
  if (
    description.slice(-1) === "y" &&
    !/[aeiou]/.test(description.slice(-2, -1))
  ) {
    return `${numberWithDelimiters(number)} ${description.slice(0, -1)}${
      number === 1 ? "y" : "ies"
    }`;
  }
  return `${numberWithDelimiters(number)} ${description}${
    number === 1 ? "" : "s"
  }`;
};

export const parseServerPathways = (pathwayData) => {
  return pathwayData.data.map((d) => {
    return { ...d.attributes, relationships: d.relationships };
  });
};

const parseActivityTracker = ({ attributes }: any) => {
  return { ...attributes };
};

export const parseServerRequests = (requestData: any): ResourceRequest[] => {
  const requests: ResourceRequest[] = [];
  const activityMap: Record<string, Activity> = {};
  const employeeMap: Record<string, Employee> = {};
  const reimbursementMap: Record<string, Reimbursement> = {};
  const activityTrackerMap: Record<string, ActivityTracker> = {};

  requestData.included.forEach((item: any) => {
    if (item.type === "activity") {
      activityMap[item.id] = parseServerActivity(item);
    } else if (item.type === "reimbursement") {
      reimbursementMap[item.id] = {
        status: item.attributes.status,
        id: item.id,
        receiptUrl: item.attributes.receipt_url,
        amount: item.attributes.amount,
        approvedAt: item.attributes.approved_at,
      };
    } else if (item.type === "employee") {
      employeeMap[item.id] = parseServerEmployee(item);
    } else if (item.type === "activityTracker") {
      activityTrackerMap[item.id] = parseActivityTracker(item);
    }
  });

  requestData.data.forEach((item: any) => {
    const activity = activityMap[item.relationships.activity.data.id];
    const platformId = Number(item.relationships.platform.data.id);

    // eslint-disable-next-line
    const platform = activity.platforms.find((p) => p.id && p.id == platformId);

    if (!platform) {
      throw new Error(`Platform could not be found for request ID ${item.id}`);
    }

    const reimbursementId = item.relationships.reimbursements.data[0]?.id;
    const employeeId = item.relationships.employee.data.id;
    const activityTrackerId = item.relationships.activity_tracker?.data?.id;

    requests.push({
      activity,
      activityTracker: activityTrackerMap[activityTrackerId],
      additionalInstructions: item.attributes.additional_instructions,
      amount: item.attributes.amount,
      approvedAt: item.attributes.approved_at,
      createdAt: item.attributes.created_at,
      deniedReason: item.attributes.deny_reason,
      employee: employeeMap[employeeId],
      enabledForCheckout: item.attributes.enabled_for_checkout,
      enabledForDebitCompany: item.attributes.enabled_for_debiting_company,
      id: item.id,
      internalTraining: item.attributes.internal_training,
      justification: item.attributes.justification,
      managerNotifiedAt: item.attributes.manager_notified_at,
      packetTrackerId: item.attributes.packet_tracker_id,
      platform,
      quizId: activity.quizId,
      reimbursement: reimbursementId ? reimbursementMap[reimbursementId] : null,
      status: item.attributes.status,
      vote: item.attributes.vote
        ? {
            id: `${item.attributes.vote.id}`,
            value: item.attributes.vote.value,
            updatedAt: item.attributes.vote.updatedAt,
          }
        : null,
    });
  });

  return requests;
};

const parseServerActivityPlatform = (platform: any): ActivityPlatform => {
  return {
    name: platform.name,
    redirectUrl: platform.redirect_url,
    price: platform.price,
    imageUrl: platform.image_url,
    platformActivityId: platform.platform_activity_id,
    id: platform.platform_id,
    inNetwork: platform.in_network,
    pricingOptions: platform.pricing_options.map(parseServerPricingOption),
  };
};

const parseServerPricingOption = (pricingOption: any): PricingOption => {
  return {
    id: pricingOption.id,
    frequency: pricingOption.frequency,
    price: pricingOption.price,
  };
};

const parsePricingOptionsData = (
  pricingOption: any,
  pricing_options: any[],
): PricingOption => {
  const option = pricing_options.find(
    (option) => option.id == pricingOption.id,
  );
  return {
    id: option.attributes.id,
    frequency: option.attributes.frequency,
    price: convertCentsToCurrency(option.attributes.price_cents),
  };
};

const parseSkillsData = (skill, skills) => {
  const record = skills.find((s) => s.id === skill.id);

  if (!record) return null;

  return {
    id: record.attributes.id,
    name: record.attributes.name,
  };
};

const skillsForActivity = (skills, relationships) => {
  if (!skills || skills.length === 0) return [];

  const list = relationships.skills.data.map((skill) =>
    parseSkillsData(skill, skills),
  );

  return list.filter((s) => s);
};

export const parseServerActivity = (
  { attributes, id, relationships }: any,
  pricing_options: PricingOption[] = [],
  skills: Skill[] = [],
): Activity => {
  let quizId = null;
  if (relationships.quizzes && relationships.quizzes.data.length > 0) {
    quizId = relationships.quizzes.data[0].id;
  }

  const activity: Activity = {
    description: attributes.description,
    duration: attributes.duration,
    endDate: attributes.end_date,
    id,
    imageUrl: attributes.image_url,
    instructor: attributes.instructor,
    location: attributes.location,
    online: attributes.online,
    startDate: attributes.start_date,
    title: attributes.title,
    quizId: quizId,
    activityTypeId: relationships.activity_type.data.id,
    categoryIds: relationships.categories.data.map(({ id }: any) => id) || [],
    platforms: attributes.platforms.map(parseServerActivityPlatform),
    pricingOptions: pricing_options?.length
      ? relationships.pricing_options.data.map(function (pricingOption) {
          return parsePricingOptionsData(pricingOption, pricing_options);
        })
      : [],
    rating: attributes.rating.score ? attributes.rating : null,
    skills: skillsForActivity(skills, relationships),
    verified: attributes.verified,
  };

  return activity;
};

export const parseServerPlatform = ({ attributes, id }: any): Platform => {
  const platform: Platform = {
    id,
    name: attributes.name,
    imageUrl: attributes.image_url,
    resourceCount: attributes.resources_count,
    affiliateUrl: attributes.affiliate_url,
  };
  return platform;
};

export const parseServerPaginationData = (pagy: any): PaginationData => {
  return {
    totalItems: pagy.count,
    itemsPerPage: pagy.items,
    currentPage: pagy.page,
  };
};

export const generateActivityMap = (
  data: any[],
): { [id: string]: Activity } => {
  return data.reduce((a, b) => {
    a[b.id] = parseServerActivity(b);
    return a;
  }, {});
};

export const parseServerPack = (
  packData: any,
  activityMap: { [id: string]: Activity },
): Pack => {
  return {
    id: packData.id,
    name: packData.attributes.name,
    description: packData.attributes.description,
    employeeId: `${packData.attributes.employee_id}`,
    visibility: packData.attributes.visibility,
    creator: packData.attributes.creator,
    activities: packData.relationships.activities.data.map(
      ({ id }: any) => activityMap[id],
    ),
  };
};

export const parseServerEmployeeSubordinate = (
  employeeData: any,
): EmployeeSubordinate => {
  return {
    firstName: employeeData.attributes.first_name,
    lastName: employeeData.attributes.last_name,
    id: employeeData.id,
    imageUrl: employeeData.attributes.image_url,
    availableBudget: employeeData.attributes.available_budget,
  };
};

export const parseServerDepartment = (departmentData: any): Department => {
  return {
    name: departmentData.attributes.name,
    id: departmentData.attributes.id,
  };
};

export const filterDepartments = (data: any): any => {
  return data.type == "department";
};

export const parseServerCompany = (companyData: any): Company => {
  return {
    name: companyData.attributes.name,
    id: companyData.attributes.id,
  };
};

export const parseInitialServerData = (
  initialData: any,
  key: string,
): {
  items: Activity[];
  paginationData: PaginationData;
} => {
  const items = initialData.resources[key].data.map(function (resources) {
    const pricing_options = initialData.resources[key].included.filter(
      (obj) => obj.type === "pricing_option",
    );
    const skills = initialData.resources[key].included.filter(
      (obj) => obj.type === "skill",
    );
    return parseServerActivity(resources, pricing_options, skills);
  });
  return {
    items,
    paginationData: parseServerPaginationData(initialData.resources[key].links),
  };
};

export const parseTrendingServerData = (
  trendingData: any,
): {
  items: Activity[];
  paginationData: PaginationData;
} => {
  const items = trendingData.data.map(parseServerActivity);
  return {
    items,
    paginationData: parseServerPaginationData(trendingData.links),
  };
};

export const parsePackServerData = (packData: {
  data: any[];
  included: any[];
  links: any;
}): {
  items: Pack[];
  paginationData: PaginationData;
} => {
  let items: any[] = [];
  if (packData.included) {
    const activityMap = packData.included.reduce<{
      [id: string]: Activity;
    }>((a, b) => {
      const activity = parseServerActivity(b);
      a[activity.id] = activity;
      return a;
    }, {});
    items = packData.data.map((serverPack) =>
      parseServerPack(serverPack, activityMap),
    );
  }
  return {
    items,
    paginationData: parseServerPaginationData(packData.links),
  };
};

export const parseServerEmployee = (data: any): Employee => {
  return {
    firstName: data.attributes.first_name,
    lastName: data.attributes.last_name,
    id: `${data.attributes.id}`,
    active: data.attributes.active,
    imageUrl: data.attributes.image_url,
    budgetShared: data.attributes.shared_budget,
    hideIndividualBudget: data.attributes.hide_individual_budget,
  };
};

export const parseShowEmployee = (data: any): ShowEmployee => {
  const wishlist: Array<Activity & { wishlistId: string }> = [];
  const wishlistMap: Map<string, string> = new Map(); // Map in the shape of Map<activityId, wishlistId>
  const activityMap: Map<string, Activity> = new Map(); // Map in the shape of Map<activityId, activity>

  data.included.forEach((item: any) => {
    if (item.type === "wishlist_item") {
      const wishlistId = item.id;
      const activityId = `${item.attributes.activity_id}`;
      // We don't have the full activity but we don't need it for the dashboard page
      // so we're just using the wishlist ID and activity ID
      wishlistMap.set(activityId, wishlistId);
      const activity = activityMap.get(activityId);
      if (activity) {
        wishlist.push({ ...activity, wishlistId });
      } else {
        wishlist.push({ id: activityId, wishlistId } as any);
      }
    } else if (item.type == "activity") {
      const activity = parseServerActivity(item);
      activityMap.set(item.id, activity);
    }
  });

  const budget = (data.included as any[]).find(({ type }) => type === "budget");

  let budgets = [];

  if (budget) {
    budgets = budgetParser([budget]);
  }

  const manager = (data.included as any[]).find(
    ({ type }) => type === "employee",
  );

  return {
    firstName: data.data.attributes.first_name,
    lastName: data.data.attributes.last_name,
    id: `${data.data.attributes.id}`,
    imageUrl: data.data.attributes.image_url,
    workEmail: data.data.attributes.work_email,
    pronouns: data.data.attributes.pronouns,
    phone: data.data.attributes.phone,
    manager: manager ? parseServerEmployee(manager) : null,
    wishlist: wishlist,
    activities: activityMap,
    budget: budgets.length > 0 ? budgets[0] : null,
  };
};

export const parseEmployeePackData = (
  employeePackData: any,
): { assigneeId: string; packId: string } => {
  return {
    assigneeId: `${employeePackData.attributes.employee_id}`,
    packId: `${employeePackData.id}`,
  };
};

export const parseDepartmentPackData = (
  departmentPackData: any,
): { assigneeId: string; packId: string } => {
  return {
    assigneeId: `${departmentPackData.attributes.department_id}`,
    packId: `${departmentPackData.id}`,
  };
};

export const parseCompanyPackData = (
  companyPackData: any,
): { assigneeId: string; packId: string } => {
  return {
    assigneeId: `${companyPackData.attributes.company_id}`,
    packId: `${companyPackData.id}`,
  };
};

export const parseErrorMessage = (error: any): string => {
  if (error.response?.text) {
    try {
      return `Error - ${JSON.parse(error.response.text).error}`;
    } catch {
      return `Error - ${error.response.text}`;
    }
  }
  if (error.message) {
    return `Error - ${error.message}`;
  }
  return "An error occurred - try again later.";
};

export const getCsrfToken = (): string =>
  (document.getElementsByName("csrf-token") as any)[0]?.content;

export const getRequest = (url: string) => {
  return superagent.get(url);
};

export const postRequest = (url: string, data: Record<any, any>) => {
  return superagent
    .post(url)
    .send(data)
    .set("X-CSRF-Token", getCsrfToken())
    .set("Accept", "application/json")
    .set("Content-Type", "application/json");
};

export const patchRequest = (url: string, data: Record<any, any>) => {
  return superagent
    .patch(url)
    .send(data)
    .set("X-CSRF-Token", getCsrfToken())
    .set("Accept", "application/json")
    .set("Content-Type", "application/json");
};

export const patchRequestFileUpload = (url: string, data: Record<any, any>) => {
  return superagent
    .patch(url)
    .send(data)
    .set("X-CSRF-Token", getCsrfToken())
    .set("Accept", "application/json");
};

export const deleteRequest = (url: string) => {
  return superagent
    .del(url)
    .set("X-CSRF-Token", getCsrfToken())
    .set("Accept", "application/json")
    .set("Content-Type", "application/json");
};

const DATE_FORMAT = "M/d/yyyy h:mm aa";
const DATE_FORMAT_NO_TIME = "M/d/yyyy";

export const formatDate = (value: string | null) => {
  if (!value) {
    return "";
  }
  return format(parseISO(value), DATE_FORMAT);
};

export const formatDateNoTime = (value: string | null) => {
  if (!value) {
    return "";
  }
  return format(parseISO(value), DATE_FORMAT_NO_TIME);
};

export const formatDateISO = (value: string) => {
  if (!value) {
    return "";
  }
  value = value.toUpperCase();
  // if AM/PM isn't included we use a different format
  if (!value.includes("PM") && !value.includes("AM")) {
    return formatISO(parse(value, DATE_FORMAT_NO_TIME, new Date()));
  }

  return formatISO(parse(value, DATE_FORMAT, new Date()));
};

export const convertPriceToCents = (price: string) => {
  return Number(price.replace(/\D/g, ""));
};

export const convertCentsToCurrency = (cents: number) =>
  `$${(cents / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;

export const generateInstructorLabel = (resourceType: string) => {
  if (resourceType === "Conference") {
    return "Organizer";
  } else if (resourceType === "Book" || resourceType === "Publication") {
    return "Author";
  } else if (resourceType === "Podcast") {
    return "Host";
  }
  return "Instructor";
};

export const calculateDaysInPast = (
  dateString: string | null | undefined,
): number => {
  return dateString ? differenceInDays(new Date(), parseISO(dateString)) : 0;
};

// functions for creating cookies to show modal to new users on MarketplacePage
export const cookie_set = function (expiredays, cookie_name, value) {
  var exp = new Date();
  exp.setDate(exp.getDate() + expiredays);
  document.cookie =
    cookie_name +
    "=" +
    value +
    ";path=/" +
    (expiredays == null || expiredays == 0
      ? ""
      : ";expires=" + exp.toUTCString());
};

export const cookie_check = function (cookie) {
  if (cookie == null || cookie == "") {
    return false;
  } else {
    return true;
  }
};

export const cookie_get = function (name) {
  if (document.cookie.length > 0) {
    var cookie_start = document.cookie.indexOf(name + "=");
    if (cookie_start != -1) {
      cookie_start = cookie_start + name.length + 1;
      var c_end = document.cookie.indexOf(";", cookie_start);
      if (c_end == -1) {
        c_end = document.cookie.length;
      }
      return document.cookie.substring(cookie_start, c_end);
    }
  }
  return "";
};
