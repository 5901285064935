import * as React from "react";

const PersonalDevelopment: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
    >
      <path
        d="M10.833 13.75H9.16634C7.32551 13.75 5.83301 12.2575 5.83301 10.4167V3.75002C5.83301 3.29002 6.20634 2.91669 6.66634 2.91669H13.333C13.793 2.91669 14.1663 3.29002 14.1663 3.75002V10.4167C14.1663 12.2575 12.6738 13.75 10.833 13.75Z"
        strokeWidth="2"
      />
      <path d="M10 13.75V17.0833" strokeWidth="2" />
      <path
        d="M7.5 17.0833H12.5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.167 5.41669H16.667C17.127 5.41669 17.5003 5.79002 17.5003 6.25002V7.91669C17.5003 9.29752 16.3812 10.4167 15.0003 10.4167H14.167"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83333 5.41669H3.33333C2.87333 5.41669 2.5 5.79002 2.5 6.25002V7.91669C2.5 9.29752 3.61917 10.4167 5 10.4167H5.83333"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PersonalDevelopment;
