import * as React from "react";

const Books: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 21C11.6994 20.3983 11.2369 19.8924 10.6645 19.5391C10.0921 19.1859 9.4326 18.9992 8.76 19.0002H4.4994C4.3025 19.0002 4.10752 18.9614 3.9256 18.886C3.74369 18.8107 3.57839 18.7002 3.43916 18.561C3.29993 18.4218 3.18948 18.2565 3.11413 18.0746C3.03878 17.8926 3 17.6977 3 17.5008V17.5008V5.49977C3 5.30286 3.03878 5.10789 3.11413 4.92597C3.18948 4.74405 3.29993 4.57876 3.43916 4.43953C3.57839 4.3003 3.74369 4.18985 3.9256 4.1145C4.10752 4.03915 4.3025 4.00036 4.4994 4.00037H8.0004C8.52563 4.00037 9.04573 4.10382 9.53098 4.30482C10.0162 4.50582 10.4571 4.80042 10.8285 5.17182C11.1999 5.54322 11.4946 5.98413 11.6955 6.46939C11.8965 6.95464 12 7.47473 12 7.99997V7.99997V21Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 21C12.3006 20.3983 12.7631 19.8924 13.3355 19.5391C13.9079 19.1859 14.5674 18.9992 15.24 19.0002H19.5042C19.9012 18.9992 20.2817 18.8408 20.5621 18.5597C20.8425 18.2786 21 17.8978 21 17.5008V5.49977C21 5.30286 20.9612 5.10789 20.8859 4.92597C20.8105 4.74405 20.7001 4.57876 20.5608 4.43953C20.4216 4.3003 20.2563 4.18985 20.0744 4.1145C19.8925 4.03915 19.6975 4.00036 19.5006 4.00037H15.9996C14.9388 4.00037 13.9215 4.42175 13.1715 5.17182C12.4214 5.92189 12 6.93921 12 7.99997V7.99997V21Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Books;
