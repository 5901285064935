import * as React from "react";

const LogoIcon: React.FC<{ size?: number | string }> = ({ size = 12 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4914 3.18658L5.84588 0.428589L1.51915 2.97018C0.976545 3.28849 0.642883 3.87075 0.642883 4.49969V5.62455V6.21128V7.71074L5.84588 4.35365L11.3572 7.71074V6.4731V5.62455V4.70927C11.3572 4.08501 11.0282 3.50596 10.4914 3.18658Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99436 8.52354L5.83982 6.65674L2.77247 8.52354C2.31728 8.79026 2.30257 9.4514 2.74539 9.73794L5.83982 11.6649L8.99991 9.73794C9.45637 9.46057 9.4536 8.79687 8.99436 8.52354Z"
      />
    </svg>
  );
};

export default LogoIcon;
