import * as React from "react";

const Business: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
    >
      <path
        d="M15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0119C2.67559 16.6993 2.5 16.2754 2.5 15.8334V8.33335C2.5 7.89133 2.67559 7.4674 2.98816 7.15484C3.30072 6.84228 3.72464 6.66669 4.16667 6.66669H15.8333C16.2754 6.66669 16.6993 6.84228 17.0118 7.15484C17.3244 7.4674 17.5 7.89133 17.5 8.33335V15.8334C17.5 16.2754 17.3244 16.6993 17.0118 17.0119C16.6993 17.3244 16.2754 17.5 15.8333 17.5Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3337 17.5V4.99998C13.3337 4.55795 13.1581 4.13403 12.8455 3.82147C12.5329 3.50891 12.109 3.33331 11.667 3.33331H8.33366C7.89163 3.33331 7.46771 3.50891 7.15515 3.82147C6.84259 4.13403 6.66699 4.55795 6.66699 4.99998V17.5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Business;
