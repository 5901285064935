import * as React from "react";

const Microsoft: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <title>MS-SymbolLockup</title>
      <rect x="2" y="2" width="9" height="9" fill="#f25022" />
      <rect x="2" y="12" width="9" height="9" fill="#00a4ef" />
      <rect x="12" y="2" width="9" height="9" fill="#7fba00" />
      <rect x="12" y="12" width="9" height="9" fill="#ffb900" />
    </svg>
  );
};

export default Microsoft;
