import * as React from "react";

const FormLabel: React.FC<{
  name?: string;
  label: string;
  optional?: boolean;
}> = ({ name, label, optional }) => {
  return (
    <label htmlFor={name} className="font-medium text-sm block mb-1">
      {label}
      {optional && <span className="text-gray-400"> (optional)</span>}
    </label>
  );
};

export default FormLabel;
