import * as React from "react";

const DataScience: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
    >
      <path
        d="M5.83366 16.6666C5.28112 16.6666 4.75122 16.4471 4.36052 16.0564C3.96982 15.6657 3.75033 15.1358 3.75033 14.5833V12.0833C3.75033 11.5308 3.53083 11.0009 3.14013 10.6102C2.74943 10.2195 2.21953 9.99998 1.66699 9.99998C1.94058 9.99998 2.21149 9.94609 2.46425 9.84139C2.71701 9.7367 2.94668 9.58324 3.14013 9.38978C3.33359 9.19633 3.48704 8.96666 3.59174 8.7139C3.69644 8.46114 3.75033 8.19023 3.75033 7.91665V5.41665C3.75033 4.86411 3.96982 4.33421 4.36052 3.94351C4.75122 3.55281 5.28112 3.33331 5.83366 3.33331"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.167 3.33331C14.7195 3.33331 15.2494 3.55281 15.6401 3.94351C16.0308 4.33421 16.2503 4.86411 16.2503 5.41665V7.91665C16.2503 8.19023 16.3042 8.46114 16.4089 8.7139C16.5136 8.96666 16.6671 9.19633 16.8605 9.38978C17.2512 9.78048 17.7811 9.99998 18.3337 9.99998C17.7811 9.99998 17.2512 10.2195 16.8605 10.6102C16.4698 11.0009 16.2503 11.5308 16.2503 12.0833V14.5833C16.2503 15.1358 16.0308 15.6657 15.6401 16.0564C15.2494 16.4471 14.7195 16.6666 14.167 16.6666"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.22884 10.0683C7.22884 10.0601 7.2264 10.052 7.22182 10.0452C7.21724 10.0383 7.21073 10.033 7.20312 10.0298C7.19551 10.0267 7.18713 10.0259 7.17905 10.0275C7.17096 10.0291 7.16354 10.033 7.15771 10.0389C7.15188 10.0447 7.14792 10.0521 7.14631 10.0602C7.1447 10.0683 7.14553 10.0767 7.14868 10.0843C7.15183 10.0919 7.15717 10.0984 7.16403 10.103C7.17088 10.1076 7.17893 10.11 7.18717 10.11C7.19265 10.11 7.19806 10.1089 7.20312 10.1068C7.20817 10.1047 7.21277 10.1017 7.21664 10.0978C7.22051 10.0939 7.22357 10.0893 7.22567 10.0843C7.22776 10.0792 7.22884 10.0738 7.22884 10.0683"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0413 10.0608C10.0413 10.0498 10.037 10.0392 10.0291 10.0314C10.0213 10.0236 10.0107 10.0192 9.99967 10.0192C9.98862 10.0192 9.97803 10.0236 9.97021 10.0314C9.9624 10.0392 9.95801 10.0498 9.95801 10.0608C9.95801 10.0719 9.9624 10.0825 9.97021 10.0903C9.97803 10.0981 9.98862 10.1025 9.99967 10.1025C10.0107 10.1025 10.0213 10.0981 10.0291 10.0903C10.037 10.0825 10.0413 10.0719 10.0413 10.0608"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5413 10.0608C12.5413 10.0498 12.537 10.0392 12.5291 10.0314C12.5213 10.0236 12.5107 10.0192 12.4997 10.0192C12.4886 10.0192 12.478 10.0236 12.4702 10.0314C12.4624 10.0392 12.458 10.0498 12.458 10.0608C12.458 10.0719 12.4624 10.0825 12.4702 10.0903C12.478 10.0981 12.4886 10.1025 12.4997 10.1025C12.5107 10.1025 12.5213 10.0981 12.5291 10.0903C12.537 10.0825 12.5413 10.0719 12.5413 10.0608"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DataScience;
