import * as React from "react";
import OnboardingIllustration from "../../illustrations/OnboardingIllustration";

const OnboardingPageContainer: React.FC = ({ children }) => {
  return (
    <main className="h-screen bg-gray-100 flex items-center justify-center">
      <div className="h-5/6 rounded-xl bg-white shadow-md w-10/12 xl:w-2/3 flex onboarding-container overflow-hidden max-w-screen-lg max-h-onboarding">
        <div className="flex-grow p-10 flex flex-col overflow-auto">
          {children}
        </div>
        <div className="w-80 bg-secondary flex items-end">
          <OnboardingIllustration />
        </div>
      </div>
    </main>
  );
};

export default OnboardingPageContainer;
