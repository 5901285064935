import * as React from "react";
import { buttonBaseStyles } from "./styles";

export type ButtonVariants =
  | "primaryContained"
  | "primaryOutlined"
  | "secondaryContained"
  | "secondaryOutlined"
  | "secondaryBorderless"
  | "white"
  | "red"
  | "redOutlined"
  | "redContained"
  | "greenContained"
  | "pinkContained"
  | "purpleContained"
  | "blueContained"
  | "secondaryBlueContained";

interface ButtonProps {
  className?: string;
  disabled?: boolean;
  variant: ButtonVariants;
  onClick?: (e: React.MouseEvent) => void;
  type?: "button" | "submit" | "reset";
  size?: "small" | "normal" | "large" | "huge";
  fullWidth?: boolean;
  padding?: string;
}

const buttonStyles = {
  primaryContained:
    "text-white border-primary border bg-primary hover:bg-primary-alt hover:border-primary-alt",
  primaryOutlined:
    "text-primary border-primary border-2 bg-white hover:border-primary-alt hover:text-primary-alt",
  secondaryContained:
    "text-white border-secondary border bg-secondary hover:bg-secondary-alt hover:border-secondary-alt",
  secondaryOutlined:
    "text-secondary border-secondary border-2 bg-white hover:border-secondary-alt hover:text-secondary-alt",
  secondaryBorderless:
    "text-secondary border-transparent border-2 bg-white hover:text-secondary-alt",
  white:
    "text-primary border-white border-2 bg-white hover:border-gray-100 hover:bg-gray-100",
  red: "text-red border-red bg-white hover:bg-red-light",
  redOutlined: "text-red border-red border-2 bg-white hover:bg-red-light",
  redContained:
    "text-white border-red border bg-red hover:bg-red-dark hover:border-red-dark",
  greenContained:
    "text-white border-green border bg-green hover:bg-green-dark hover:border-green-dark",
  pinkContained:
    "text-white border-pink border bg-pink hover:bg-pink-alt hover:border-pink-alt",
  purpleContained:
    "text-white border-purple border bg-purple hover:bg-purple-alt hover:border-purple-alt",
  blueContained:
    "text-white border-blue border bg-blue hover:bg-blue-alt hover:border-blue-alt",
  secondaryBlueContained:
    "text-lightBlue border-transparent border-2 bg-transparent hover:text-lightBlue-alt",
};

const buttonSizes = {
  small: "text-sm rounded",
  normal: "text-base rounded",
  large: "text-lg rounded",
  huge: "text-xl rounded-lg",
};

const buttonPadding = {
  small: "px-2 py-1",
  normal: "px-4 py-1.5",
  large: "px-5 py-3",
  huge: "px-6 py-4",
};

const Button: React.FC<ButtonProps> = ({
  className = "",
  children,
  disabled,
  variant,
  onClick,
  type = "button",
  size = "normal",
  fullWidth,
  padding,
}) => {
  const styles = `${buttonBaseStyles} ${className} transition-colors transition-duration-150 ${
    buttonStyles[variant]
  } ${buttonSizes[size]} ${padding || buttonPadding[size]} ${fullWidth ? "w-full" : ""}`;

  return (
    <button
      disabled={disabled}
      className={styles}
      onClick={onClick}
      type={type}
    >
      {children}
    </button>
  );
};

export default Button;
