import * as React from "react";

const Tools: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 28 28"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.62.79h-2.14c-1.84,0-3.33,1.49-3.33,3.33v16.89c0,.17.04.35.13.52l3.06,5.08c.26.44.7.7,1.22.7s.96-.26,1.23-.7l3.06-5.08c.09-.17.13-.35.13-.52V4.11c-.04-1.84-1.53-3.33-3.37-3.33ZM20.48,2.71h2.14c.74,0,1.36.61,1.36,1.36v1.18h-4.86v-1.14c0-.74.61-1.4,1.36-1.4ZM21.57,24.72l-2.45-4.02V7.26h4.86v13.43l-2.41,4.02Z"
        stroke="currentColor"
        strokeWidth="0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.33.83h-5.56c-1.49,0-2.71,1.23-2.71,2.71v20.87c0,1.49,1.23,2.71,2.71,2.71h5.56c1.49,0,2.71-1.23,2.71-2.71V3.54c.04-1.49-1.18-2.71-2.71-2.71ZM11.11,24.46c0,.44-.35.79-.79.79h-5.56c-.44,0-.79-.35-.79-.79v-1.53h3.06c.53,0,.96-.44.96-.96s-.44-.96-.96-.96h-3.06v-2.19h.87c.53,0,.96-.44.96-.96s-.44-.96-.96-.96h-.87v-2.14h3.06c.53,0,.96-.44.96-.96s-.44-.96-.96-.96h-3.06v-2.14h.87c.53,0,.96-.44.96-.96s-.44-.96-.96-.96h-.87v-2.14h3.06c.53,0,.96-.44.96-.96s-.44-.96-.96-.96h-3.06v-1.14c0-.44.35-.79.79-.79h5.56c.44,0,.79.35.79.79v20.91Z"
        stroke="currentColor"
        strokeWidth="0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Tools;
