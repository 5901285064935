import * as React from "react";

const OnboardingIllustration: React.FC = () => {
  return (
    <svg
      width="320"
      height="496"
      viewBox="0 0 320 496"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M120.156 655.879L120.156 655.878L120.144 655.879C67.0651 659.699 14.6381 635.819 -14.8397 588.783C-46.209 538.5 -42.6806 475.646 -10.9454 427.49C-7.26003 421.902 -3.29911 416.198 0.734646 410.389C7.60476 400.495 14.6862 390.297 20.9773 379.853C30.9685 363.267 39.0059 346.002 41.1057 328.216C48.2207 268.043 61.9458 212.098 97.6278 164.343C113.313 143.37 130.174 126.439 147.781 113.26L147.785 113.257C217.441 59.3102 318.558 64.1624 384.723 128.471C438.177 180.425 453.923 256.73 431.036 322.106L431.032 322.117L431.029 322.128C422.742 353.135 407.592 385.152 386.859 418.182C332.972 503.93 282.616 562.826 237.566 600.549C192.513 638.275 152.788 654.809 120.156 655.879Z"
        stroke="white"
        strokeWidth="0.61105"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M179.762 160.365C189.694 160.365 197.74 168.411 197.74 178.343C197.74 188.274 189.694 196.321 179.762 196.321C169.831 196.321 161.784 188.274 161.784 178.343C161.784 168.411 169.831 160.365 179.762 160.365Z"
        fill="#050F4A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M162.933 184.826C159.439 177.285 149.186 140.593 149.048 136.547C148.726 128.317 155.209 121.374 163.531 121.052C171.854 120.73 178.888 127.121 179.21 135.352C179.486 141.559 195.487 162.48 197.372 174.618C200.085 192.091 164.819 188.826 162.933 184.826Z"
        fill="#FEB400"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M179.762 160.365C189.694 160.365 197.74 168.411 197.74 178.343C197.74 188.274 189.694 196.321 179.762 196.321C169.831 196.321 161.784 188.274 161.784 178.343C161.784 168.411 169.831 160.365 179.762 160.365Z"
        fill="#050F4A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M213.098 186.941C202.154 217.058 170.107 194.711 167.67 191.723C161.278 181.423 165.876 183.814 165.187 177.331C165.049 176.503 165.003 175.63 165.003 174.802C165.003 165.928 171.9 158.663 180.636 158.065C184.682 157.514 193.142 156.686 199.166 158.387C199.534 158.479 199.901 158.571 200.223 158.663C211.12 162.066 217.466 174.894 213.098 186.941Z"
        fill="#FEB400"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M195.257 173.101C188.728 172.825 183.394 176.825 183.348 182.113C183.302 187.355 188.59 191.861 195.119 192.136C201.648 192.412 206.982 188.412 207.028 183.124C207.074 177.883 201.832 173.377 195.257 173.101Z"
        fill="#FEB400"
      />
      <path
        d="M183.348 182.067C183.302 187.309 188.59 191.815 195.119 192.09C201.648 192.366 206.982 188.366 207.028 183.078"
        stroke="#050F4A"
        strokeWidth="0.919595"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M196.728 164.135C190.199 163.859 184.866 167.859 184.82 173.147C184.774 178.389 190.061 182.895 196.59 183.171C203.12 183.447 208.453 179.446 208.499 174.159C208.499 168.963 203.258 164.457 196.728 164.135Z"
        fill="#010623"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M207.304 174.159C207.258 178.894 202.476 182.481 196.59 182.251C190.705 181.975 185.969 177.929 186.015 173.239C186.015 172.825 186.061 172.457 186.107 172.089L184.912 150.755L200.315 144.134L206.798 171.538C207.12 172.319 207.304 173.239 207.304 174.159Z"
        fill="#6E404B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M196.821 156.64C193.602 160.319 189.234 162.158 185.556 161.698L184.958 150.709L200.177 144.134C201.373 147.812 200.223 152.732 196.821 156.64Z"
        fill="#010623"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M191.303 140.041C191.303 140.041 178.612 150.801 187.165 154.065C193.28 156.41 205.281 147.628 206.246 137.237C206.982 128.501 191.303 140.041 191.303 140.041Z"
        fill="#6E404B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M198.108 147.674C191.349 151.996 186.889 151.766 184.176 150.525C184.176 149.146 184.82 147.628 185.786 146.157C193.832 144.318 207.626 141.605 198.108 147.674Z"
        fill="#010623"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M193.418 107.672L207.81 107.442C208.545 107.442 208.959 107.994 208.729 108.637L197.464 146.525C197.28 147.214 196.499 147.766 195.809 147.766L181.417 147.996C180.682 147.996 180.268 147.444 180.498 146.801L191.809 108.959C191.947 108.27 192.728 107.672 193.418 107.672Z"
        fill="#010623"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M187.854 144.502C182.659 145.191 182.429 140.639 184.682 139.996C181.279 139.628 180.865 136.087 185.463 134.386C191.809 132.041 199.487 131.811 199.487 131.811L198.935 130.202C198.935 130.202 196.177 131.305 194.429 131.903C190.429 133.191 188.268 128.914 195.395 124.822C199.441 122.523 202.614 121.696 204.637 125.282C206.752 129.052 209.833 136.455 202.108 145.973C200.131 152.456 191.165 149.375 189.51 148.272C187.165 146.709 187.303 145.559 187.854 144.502Z"
        fill="#6E404B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M205.373 119.994C205.373 119.994 206.293 122.523 206.568 124.454C207.442 130.432 206.844 135.536 206.798 136.271L203.212 127.121L205.373 119.994Z"
        fill="#6E404B"
      />
      <path
        d="M183.992 140.087C183.992 140.087 196.453 136.455 204.223 138.846"
        stroke="#010623"
        strokeWidth="0.919595"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M187.9 144.685C191.441 144.364 197.924 144.042 201.832 145.835"
        stroke="#010623"
        strokeWidth="0.919595"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M194.659 131.949C194.521 131.949 203.212 130.937 205.878 133.604"
        stroke="#010623"
        strokeWidth="0.919595"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M194.936 131.857L202.016 129.098"
        stroke="#010623"
        strokeWidth="0.919595"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M173.831 147.398C174.107 147.766 174.383 148.134 174.659 148.456C176.82 151.353 177.647 155.031 176.82 158.525C176.038 161.698 174.337 165.836 170.612 170.618C173.371 177.837 176.36 191.769 167.992 197.424C164.773 199.585 128.357 202.022 116.08 199.309C108.77 197.7 108.31 192.275 112.08 184.228C114.103 179.952 106.884 155.399 103.712 145.099C99.6196 142.938 96.8608 138.616 96.8608 133.65C96.8608 126.478 102.654 120.684 109.827 120.684C110.379 120.684 110.885 120.73 111.39 120.776C135.852 120.776 154.198 121.374 163.531 121.006C171.854 120.684 178.889 127.075 179.211 135.306C179.394 140.179 177.279 144.548 173.831 147.398Z"
        fill="#FEB400"
      />
      <path
        d="M154.151 142.111C155.255 145.237 156.083 148.134 156.312 150.157C157.002 156.364 155.853 169.101 153.278 193.148"
        stroke="#050F4A"
        strokeWidth="0.919595"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M167.991 197.47C176.314 191.815 173.325 177.883 170.612 170.664C174.291 165.836 176.038 161.698 176.819 158.571C177.693 155.077 176.819 151.399 174.658 148.502C172.635 145.789 169.187 141.191 163.761 134.064"
        stroke="#050F4A"
        strokeWidth="0.919595"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M120.724 140.731C114.793 153.146 108.815 161.652 108.264 180.872C107.942 192.55 114.195 195.355 109.689 208C104.631 222.207 68.997 222.023 70.3304 200.505C71.7558 177.469 93.3663 136.961 98.0562 128.501C100.079 123.995 104.631 120.822 109.873 120.822C117.046 120.822 122.839 126.615 122.839 133.788C122.793 136.317 122.012 138.708 120.724 140.731Z"
        fill="#FEB400"
      />
      <path
        d="M116.908 148.87C109.551 163.675 108.218 169.607 107.988 180.09"
        stroke="#050F4A"
        strokeWidth="0.919595"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.5264 224.139C75.5264 224.139 75.5723 224.552 75.5723 224.782C75.5723 226.484 81.5037 227.863 88.8145 227.863C96.1253 227.863 102.057 226.484 102.057 224.782C102.057 224.644 104.402 210.896 104.402 210.896H104.356C104.356 210.851 104.356 210.851 104.356 210.805C104.356 209.103 98.4243 207.724 91.1135 207.724C83.8027 207.724 77.8713 209.103 77.8713 210.805C77.8713 210.896 77.9173 210.988 77.9173 211.08L75.5264 224.139Z"
        fill="#FEB400"
      />
      <path
        d="M104.356 210.942H104.31C104.31 210.896 104.31 210.896 104.31 210.85C104.31 209.149 98.3781 207.77 91.0674 207.77C83.7566 207.77 77.8252 209.149 77.8252 210.85C77.8252 210.942 77.8712 211.034 77.8712 211.126"
        stroke="#050F4A"
        strokeWidth="0.919595"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88.7684 222.345C95.3895 222.345 100.815 223.633 100.815 225.196C100.815 226.759 95.4354 228.047 88.7684 228.047C82.1473 228.047 76.7217 226.759 76.7217 225.196C76.7217 223.633 82.1013 222.345 88.7684 222.345Z"
        fill="#010623"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.6761 263.773C81.6414 265.98 73.549 269.566 68.6292 272.693L66.9739 273.981C66.0543 274.716 64.7669 274.624 64.1232 273.751C63.5254 272.969 63.6634 271.773 64.445 271.038C64.445 271.038 69.2269 267.313 72.5375 261.566L60.8126 261.474L60.4448 261.612C60.3528 261.658 60.2609 261.704 60.1229 261.75C59.939 261.842 59.7091 261.98 59.5252 262.072C59.1573 262.302 58.7895 262.624 58.4677 262.945C58.1458 263.267 57.8699 263.681 57.64 264.095L57.5481 264.279L57.4101 264.509L57.1802 265.014L56.6744 265.98L51.1109 278.533C50.5132 279.682 49.1338 280.234 47.9843 279.774C46.9267 279.314 46.5129 278.119 46.9267 277.015L51.6167 264.141L51.9845 263.129L52.1684 262.624L52.2604 262.348L52.3983 262.026C52.7662 261.106 53.2719 260.279 53.8697 259.451C54.4674 258.669 55.1571 257.934 55.9388 257.244C56.3526 256.922 56.7204 256.6 57.1802 256.324C57.4101 256.186 57.594 256.048 57.8239 255.91L58.4217 255.589L80.4 243.772C80.446 243.726 80.446 243.68 80.446 243.68C80.0781 243.68 86.7912 241.105 86.9291 240.967C99.4816 253.014 84.6761 263.727 84.6761 263.773Z"
        fill="#6E404B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.3782 225.61L91.8031 254.117H75.0205L78.5609 225.656V225.61C78.5609 224.323 82.975 223.311 88.4466 223.311C93.9642 223.311 98.3782 224.323 98.3782 225.61Z"
        fill="#6E404B"
      />
      <path
        d="M42.3287 272.095L50.3752 261.014L51.0189 260.141L51.3407 259.727L51.5246 259.497L51.7545 259.221C52.3523 258.439 53.088 257.75 53.8696 257.152C54.6513 256.554 55.5249 256.048 56.4905 255.635C56.9503 255.451 57.4101 255.267 57.9158 255.083C58.1457 254.991 58.4216 254.945 58.6515 254.899L59.3412 254.761L84.5381 249.197C88.8602 248.232 89.8718 257.934 81.4575 262.761L59.9849 260.968L59.5711 261.014C59.4791 261.014 59.3412 261.06 59.2492 261.06C59.0193 261.106 58.7894 261.152 58.6055 261.198C58.1917 261.336 57.7319 261.52 57.3181 261.75C56.9043 261.98 56.5364 262.256 56.2146 262.624L56.0766 262.761L55.8927 262.945L55.5249 263.359L54.7892 264.141L46.0071 274.716C45.1335 275.682 43.6161 275.82 42.6965 275.038C41.731 274.302 41.639 273.015 42.3287 272.095Z"
        fill="#6E404B"
      />
      <path
        d="M73.917 261.658C67.9856 261.612 60.7208 261.52 60.7208 261.52L60.353 261.658C60.261 261.704 60.1691 261.75 60.0311 261.796C59.8472 261.888 59.6173 262.026 59.4334 262.118C59.0656 262.348 58.6977 262.67 58.3759 262.992C58.054 263.313 57.7781 263.727 57.5482 264.141L57.4563 264.325L57.3183 264.555L57.0884 265.061L56.5827 266.026L51.0191 278.579C50.4214 279.728 49.042 280.28 47.8925 279.82C46.8349 279.36 46.4211 278.165 46.8349 277.061L51.5249 264.187L51.8927 263.175L52.0766 262.67L52.1686 262.394L52.3065 262.072C52.6744 261.152 53.1801 260.325 53.7779 259.497C54.3756 258.715 55.0653 257.98 55.847 257.29C56.2608 256.968 56.6286 256.646 57.0884 256.37C57.3183 256.232 57.5022 256.095 57.7321 255.957L58.3299 255.635"
        stroke="#010623"
        strokeWidth="0.919595"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M139.576 113.925C148.036 114.982 154.795 117.649 154.611 119.948C154.473 122.247 147.484 123.259 138.978 122.247C130.518 121.19 123.759 118.523 123.943 116.224C124.081 113.879 131.115 112.867 139.576 113.925Z"
        fill="#010623"
      />
      <path
        d="M129.782 85.4636C128.679 86.1993 128.357 87.6707 129 88.6822C129.644 89.6938 131.115 89.9237 132.173 89.188"
        stroke="#010623"
        strokeWidth="0.919595"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M158.657 89.7396C161.876 79.1183 147.254 86.7969 141.553 91.3489C135.851 95.9009 136.817 106.614 143.714 115.304C150.611 123.994 170.658 131.627 172.405 124.546C177.003 105.373 154.243 104.085 158.657 89.7396Z"
        fill="#010623"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M154.289 102.016L152.404 118.891C152.45 119.029 152.45 119.121 152.45 119.259C152.312 121.19 146.473 121.971 139.346 121.006C132.265 120.04 126.61 117.695 126.748 115.764C126.748 115.58 126.84 115.396 126.977 115.212L134.38 90.6592L154.289 102.016Z"
        fill="#6E404B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M153.232 112.867C138.151 108.131 137.737 93.5559 137.737 93.5559L154.29 101.74L153.232 112.867Z"
        fill="#010623"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M133.507 92.4064C100.677 101.28 131.989 59.3009 142.381 58.3813C142.381 58.3813 157.876 58.5653 162.06 67.9451C162.98 71.3017 162.796 73.2788 163.991 78.5665C165.876 86.5669 164.773 101.051 155.301 104.039C149.783 105.74 139.76 104.637 133.507 92.4064Z"
        fill="#6E404B"
      />
      <path
        d="M147.3 89.9236C147.3 89.9236 151.347 95.3492 156.956 92.8663"
        stroke="#BD4040"
        strokeWidth="0.919595"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M148.864 91.4868C148.864 91.4868 150.105 89.7856 152.634 90.1534C154.197 90.3833 154.565 91.3948 154.565 91.3948C154.565 91.3948 157.048 89.9695 157.37 92.8662C155.761 94.4755 150.519 93.5559 148.864 91.4868Z"
        fill="#BD4040"
      />
      <path
        d="M155.668 73.8766C155.668 73.8766 158.473 71.6695 162.703 73.5087"
        stroke="#010623"
        strokeWidth="0.919595"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M158.657 77.509L160.864 76.1296"
        stroke="#010623"
        strokeWidth="0.919595"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M145.093 77.7388L147.576 76.5894"
        stroke="#010623"
        strokeWidth="0.919595"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M141.185 75.4399C142.518 74.3364 146.381 71.9455 150.795 74.0145"
        stroke="#010623"
        strokeWidth="0.919595"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M154.289 76.7734C154.289 76.7734 153.6 80.13 156.588 82.6588C159.531 85.1418 158.382 89.05 154.289 88.4983"
        stroke="#010623"
        strokeWidth="0.919595"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M132.955 86.889L127.759 84.2222"
        stroke="#010623"
        strokeWidth="0.919595"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M144.91 77.601C145.599 77.4631 146.243 78.3367 146.381 79.4862C146.473 80.6357 146.013 81.6932 145.323 81.7852C144.634 81.9231 143.99 81.0495 143.852 79.9C143.76 78.7505 144.22 77.739 144.91 77.601Z"
        fill="#010623"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M158.933 76.9572C159.623 76.8193 160.267 77.6929 160.405 78.8424C160.497 79.9919 160.037 81.0494 159.347 81.1414C158.657 81.2793 158.014 80.4057 157.876 79.2562C157.738 78.1527 158.244 77.0952 158.933 76.9572Z"
        fill="#010623"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M106.149 121.282C106.149 121.282 113.505 121.282 119.391 121.282C129.368 126.11 129.506 140.731 123.345 144.501H148.266C152.818 137.053 152.91 131.121 150.841 126.477C156.726 132.133 157.094 138.018 149.462 144.41C184.682 134.064 152.404 117.695 133.093 112.959C130.564 112.086 128.817 111.672 128.817 111.672C128.817 111.672 139.852 102.89 133.369 91.7627C130.978 93.326 127.759 91.6708 126.839 90.5213C124.678 87.8545 124.77 83.6703 127.253 82.061C129.736 80.4517 132.633 81.8771 134.334 83.5783C134.288 83.5324 134.288 83.5324 134.334 83.5783C134.426 83.6703 134.61 83.8542 134.656 83.9462C132.587 75.302 148.864 70.3822 146.519 62.1058C146.519 62.1058 156.083 60.8643 161.876 67.6234C161.922 64.5887 159.025 57.2319 146.795 54.933C132.541 52.2661 119.161 60.1746 119.805 74.8422C119.713 75.8997 120.034 77.1872 119.115 79.0263C116.494 84.452 79.2045 108.361 106.149 121.282ZM149.324 144.501C149.37 144.456 149.37 144.456 149.416 144.41C149.37 144.456 149.324 144.456 149.278 144.501H149.324Z"
        fill="#010623"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124.862 475.694C134.518 476.843 146.335 474.682 149.001 473.257C150.611 472.429 153.002 471.555 153.461 467.371C153.599 466.222 155.944 464.383 157.002 463.647C158.795 462.405 155.944 446.588 151.99 442.588C148.909 442.036 141.047 455.83 135.943 459.095C123.161 462.176 121 466.452 121 466.452C119.436 468.981 119.298 475.05 124.862 475.694Z"
        fill="#010623"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124.862 472.843C134.518 473.992 146.335 471.831 149.001 470.406C150.611 469.578 153.002 468.705 153.461 464.52C153.599 463.371 155.944 461.532 157.002 460.796C158.795 459.555 155.944 443.738 151.99 439.737C148.909 439.186 141.047 452.979 135.943 456.244C123.161 459.325 121 463.601 121 463.601C119.436 466.13 119.298 472.199 124.862 472.843Z"
        fill="#050F4A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M217.603 476.153C206.384 476.337 202.798 474.866 198.384 472.797C193.51 470.544 196.453 465.532 191.901 464.613C187.257 463.693 186.751 462.13 186.751 462.13C185.647 457.164 188.36 457.762 191.303 454.267C217.052 470.774 218.753 458.129 220.086 462.176C220.086 462.176 233.328 463.463 238.524 469.854C239.398 470.912 238.938 472.199 238.616 473.349C239.995 473.211 233.145 475.878 217.603 476.153Z"
        fill="#010623"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M217.603 473.073C206.384 473.257 202.798 471.785 198.384 469.716C193.51 467.463 196.452 462.452 191.9 461.532C187.257 460.612 186.751 459.049 186.751 459.049C185.647 454.083 187.073 446.956 190.061 443.508C215.81 460.015 218.799 455.095 220.086 459.141C220.086 459.141 235.443 460.612 238.662 470.268C239.995 470.176 233.144 472.797 217.603 473.073Z"
        fill="#050F4A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M144.22 306.718C143.484 322.489 147.025 320.098 149.002 340.697C150.887 360.422 152.588 408.471 152.772 435.415C152.404 437.392 153.232 441.347 153.232 441.347C153.232 441.347 150.335 445.071 150.197 447.048C149.232 460.888 147.668 463.003 124.586 461.026C128.587 456.382 136.081 440.841 134.288 431.231C126.012 357.112 122.655 346.123 122.747 324.742C122.839 299.637 111.62 271.406 107.666 249.795C103.252 225.932 122.885 200.643 122.058 206.758C131.667 205.655 122.242 228.874 129.46 229.288C130.288 229.38 145.691 228.369 145.691 228.369C145.691 228.369 147.071 244.829 144.22 306.718Z"
        fill="#6E404B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M203.579 457.807C196.269 445.899 192.314 445.669 189.556 444.979C184.636 417.069 177.003 371.135 173.233 352.468C169.14 332.191 174.29 329.202 168.911 313.891C147.852 253.795 146.289 236.185 146.289 236.185C146.289 236.185 157.875 226.023 158.657 225.885C165.738 224.828 159.669 211.77 169.6 212.046C167.577 207.448 183.67 222.805 187.624 255.588C195.947 324.466 210.338 437.07 210.338 437.07C210.338 437.07 214.017 445.715 219.35 451.738C222.339 455.095 225.144 459.187 227.581 461.164C213.327 464.888 206.108 461.854 203.579 457.807Z"
        fill="#6E404B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M152.312 411.598C143.714 411.92 135.989 408.793 131.437 406.448C130.978 402.54 130.518 398.907 130.104 395.459C136.863 393.068 149.553 395.091 149.553 395.091C149.553 395.091 150.565 396.103 151.898 397.62C152.082 402.356 152.22 407.046 152.312 411.598C152.358 411.598 152.312 411.598 152.312 411.598Z"
        fill="#010623"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M209.051 401.114C208.913 402.402 201.097 403.459 191.395 403.459C181.647 403.459 173.739 402.402 173.739 401.068H173.693L148.312 254.715L155.899 401.206H155.669C155.209 402.448 147.484 403.459 138.058 403.459C128.311 403.459 120.402 402.402 120.402 401.068C119.483 393.206 115.39 351.042 114.149 340.513C109.597 302.488 103.758 253.933 103.758 253.933C103.758 253.933 102.746 243.404 104.263 234.668C104.861 231.265 105.643 228.185 106.516 225.38C107.758 218.943 112.448 211.448 115.804 206.988V193.746V193.7C115.804 191.401 127.483 190.343 141.921 191.263C156.358 192.228 168.037 194.849 168.037 197.102C168.037 197.24 167.991 197.332 167.945 197.47L168.911 209.011C185.831 217.793 189.878 242.07 189.924 242.116L209.189 401.068L209.051 401.114Z"
        fill="#00C753"
      />
      <path
        d="M154.657 248.37L182.199 398.126"
        stroke="#050F4A"
        strokeWidth="0.919595"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M165.232 208.183C165.232 208.183 170.98 224.184 185.923 230.392"
        stroke="#050F4A"
        strokeWidth="0.919595"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M147.484 192.366V204.459L148.68 246.484C148.68 246.484 160.727 241.243 156.956 223.173C153.738 207.861 154.565 205.654 154.565 205.654"
        stroke="#050F4A"
        strokeWidth="0.919595"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M132.081 203.861C132.081 203.861 132.081 225.288 111.206 232.047"
        stroke="#050F4A"
        strokeWidth="0.919595"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M122.931 191.539V203.999C119.574 208.459 114.839 215.954 113.643 222.391C112.769 225.15 111.988 228.276 111.39 231.679C109.919 240.415 110.884 250.944 110.884 250.944C110.884 250.944 116.724 299.499 121.276 337.524C122.701 349.571 127.023 394.953 127.989 403.045"
        stroke="#050F4A"
        strokeWidth="0.919595"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M115.574 206.712V206.666C115.574 204.321 127.575 203.217 142.38 204.183C157.186 205.149 169.187 207.861 169.187 210.206"
        stroke="#050F4A"
        strokeWidth="0.919595"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M148.542 246.944L156.037 246.668C156.037 246.668 156.588 250.439 154.979 255.68C153.554 260.324 150.059 264.601 150.059 264.601L148.358 254.715L148.542 246.944Z"
        fill="#010623"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M249.983 416.932L273.295 416.886L272.651 446.956L247.179 445.623L249.983 416.932Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M277.663 466.222C277.663 466.222 271.87 467.188 266.536 467.509C261.984 467.785 262.076 473.349 254.444 476.246C248.788 478.361 239.914 478.407 229.017 476.889C218.717 475.464 214.947 473.395 214.257 472.567H214.165V472.429C214.119 472.337 214.119 472.245 214.165 472.199L214.027 467.234H217.43C218.487 466.682 219.913 466.36 221.844 466.406C230.35 465.578 245.248 448.658 247.455 438.358C249.708 436.749 254.444 439.462 258.766 441.623C263.41 448.244 268.467 444.244 268.467 444.244C268.467 444.244 270.399 441.761 272.835 439.646C277.801 446.451 278.905 464.245 277.663 466.222Z"
        fill="#010623"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M277.525 461.578C277.525 461.578 271.732 462.543 266.398 462.865C261.846 463.141 261.938 468.705 254.306 471.601C248.65 473.717 239.776 473.763 228.879 472.245C215.683 470.406 213.2 467.555 214.211 467.509C214.211 467.509 214.119 461.578 221.752 461.762C230.258 460.934 245.294 444.519 247.501 434.22C249.754 432.611 257.432 434.864 258.674 436.979C263.318 443.6 267.686 441.761 267.686 441.761C267.686 441.761 272.376 440.657 272.881 435.048C277.893 441.853 278.767 459.601 277.525 461.578Z"
        fill="#F8444F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M353.668 431.599C353.668 431.691 353.668 431.645 353.668 431.737C353.576 432.933 345.989 433.898 336.84 433.898C328.149 433.898 321.114 433.025 320.563 431.921H320.517C320.517 431.921 307.734 415.506 305.987 383.458C302.125 312.144 273.755 239.542 273.755 239.542C273.755 239.542 270.997 221.15 318.678 232.277L353.668 431.461V431.599Z"
        fill="#050F4A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M279.319 222.713C257.386 222.713 239.684 226.575 239.822 231.357C239.96 236.139 257.846 240.002 279.778 240.002C301.711 240.002 319.413 236.139 319.275 231.357C319.183 226.575 301.297 222.713 279.319 222.713Z"
        fill="#050F4A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M318.126 203.448C318.126 203.448 306.815 196.229 288.791 195.953C252.743 195.447 237.339 203.264 237.339 203.264L238.121 231.265L318.724 224.368L318.126 203.448Z"
        fill="#050F4A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M278.629 380.056C283.365 413.759 276.744 431.783 276.422 432.611C276.146 433.576 269.387 434.312 260.973 434.312C252.696 434.312 245.891 433.576 245.432 432.611H245.386L237.707 222.897C237.707 222.897 248.328 220.598 257.938 222.897C269.111 225.564 278.767 238.024 278.767 238.024C278.767 238.024 270.904 325.156 278.629 380.056Z"
        fill="#050F4A"
      />
      <path
        d="M247.96 210.759C247.96 210.759 247.455 225.15 238.581 235.22"
        stroke="#050F4A"
        strokeWidth="0.919595"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M293.526 208.505C293.526 208.505 293.802 228.874 312.47 235.587"
        stroke="#050F4A"
        strokeWidth="0.919595"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M353.576 430.909L310.861 224.506C311.091 220.598 311.918 210.988 311.918 210.988"
        stroke="#050F4A"
        strokeWidth="0.919595"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M269.157 241.289C269.157 241.289 265.939 325.018 271.226 385.665C273.617 412.794 269.203 433.576 269.203 433.576"
        stroke="#050F4A"
        strokeWidth="0.919595"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M277.526 255.451L277.847 250.715L274.629 240.002L262.03 237.657C261.984 237.657 264.513 251.956 277.526 255.451Z"
        fill="#010623"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M235.868 151.997C235.776 188.137 225.845 205.057 237.018 215.219C236.834 221.564 236.558 231.358 236.558 231.358C236.558 231.358 236.558 232.093 237.064 232.691C237.661 233.335 238.167 233.611 238.167 233.611C238.167 233.611 237.845 230.944 238.121 230.898C241.018 230.254 249.846 229.197 277.572 229.656C298.539 229.978 310.631 232.001 318.77 232.921C319.138 234.714 319.229 235.266 319.229 235.266C319.229 235.266 322.77 234.576 322.954 233.243C322.954 233.243 323.138 221.242 323.092 217.748C332.012 212.184 332.472 196.643 322.908 154.433C329.897 150.755 334.725 143.214 334.725 134.57C334.725 122.478 325.437 112.684 313.85 112.27H313.298C313.252 112.27 313.206 112.27 313.114 112.27C313.068 112.27 312.976 112.27 312.93 112.27L246.306 112.408C235.684 113.787 227.592 123.351 227.592 134.662C227.592 141.467 231.086 147.904 235.868 151.997Z"
        fill="#F8444F"
      />
      <path
        d="M236.833 215.127C247.961 212.874 293.756 209.379 323.046 217.655"
        stroke="#050F4A"
        strokeWidth="0.919595"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M260.329 124.776L274.031 136.915L290.676 124.317"
        stroke="#050F4A"
        strokeWidth="0.919595"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M265.295 145.605C258.26 156.41 240.144 175.63 225.109 184.09C221.89 188.734 216.556 191.769 210.487 191.769C200.693 191.769 192.739 183.814 192.739 174.067C192.739 167.124 196.739 161.146 202.532 158.25C202.67 158.158 202.762 158.112 202.9 158.02C218.763 150.663 227.132 131.122 228.603 127.397C231.178 118.845 239.086 112.638 248.466 112.638C259.915 112.638 269.249 121.926 269.249 133.375C269.249 137.973 267.778 142.157 265.295 145.605Z"
        fill="#F8444F"
      />
      <path
        d="M229.292 176.917L240.879 134.018"
        stroke="#050F4A"
        strokeWidth="0.919595"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M374.451 180.182C374.451 190.941 365.715 199.632 355.001 199.632C348.702 199.632 343.093 196.643 339.506 191.953L310.953 156.64C300.056 155.491 291.549 146.019 291.549 134.432C291.549 122.11 301.205 112.132 313.16 112.132C320.103 112.132 326.218 115.489 330.172 120.73L330.264 120.638L370.221 167.86L370.175 167.906C372.796 171.308 374.451 175.538 374.451 180.182Z"
        fill="#F8444F"
      />
      <path
        d="M304.976 148.824L333.437 187.033"
        stroke="#050F4A"
        strokeWidth="0.919595"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M374.451 180.182C374.451 190.941 365.715 199.631 355.002 199.631C353.254 199.631 351.553 199.402 349.944 198.942L318.172 193.7L321.482 164.549C321.482 164.549 328.012 165.239 332.38 165.744C338.587 152.548 358.818 160.227 361.025 161.606C368.75 164.135 374.451 171.492 374.451 180.182Z"
        fill="#F8444F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M323.23 165.285C319.735 162.986 315.413 167.262 313.62 174.894C311.827 182.481 313.206 190.757 316.701 193.056C320.195 195.355 324.517 190.849 326.31 183.263C328.149 175.63 326.724 167.584 323.23 165.285Z"
        fill="#010623"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M322.54 166.572C319.413 164.503 315.551 168.365 313.942 175.17C312.332 181.975 313.574 189.378 316.7 191.447C319.827 193.516 323.689 189.47 325.299 182.665C326.954 175.814 325.712 168.595 322.54 166.572Z"
        fill="#FEB400"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M312.93 165.285L321.069 166.112L318.448 192.183L309.39 191.309L312.93 165.285Z"
        fill="#FEB400"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M314.033 165.653C310.907 163.583 307.045 167.446 305.435 174.251C303.826 181.056 305.067 188.459 308.194 190.528C311.321 192.597 315.183 188.55 316.792 181.745C318.402 174.895 317.16 167.676 314.033 165.653Z"
        fill="#010623"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M313.068 166.526C310.447 164.779 307.182 168.044 305.849 173.745C304.516 179.492 305.527 185.7 308.148 187.401C310.769 189.148 314.033 185.746 315.367 179.998C316.746 174.297 315.689 168.227 313.068 166.526Z"
        fill="#EDB0A0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M285.066 163.216L289.802 173.055L285.066 163.216Z"
        fill="#EDB0A0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M293.113 182.665C287.457 179.538 285.572 174.389 281.158 170.159L279.457 168.825C278.491 168.09 278.261 166.756 278.905 165.791C279.549 164.917 280.744 164.733 281.664 165.285C281.664 165.285 286.446 168.687 292.975 170.343C303.458 168.411 314.769 192.137 293.113 182.665Z"
        fill="#EDB0A0"
      />
      <path
        d="M274.261 147.077L287.043 150.985L288.055 151.307L288.561 151.491L288.791 151.583L289.113 151.721C290.032 152.042 290.86 152.502 291.687 153.054C292.515 153.606 293.251 154.249 293.94 154.985C294.262 155.353 294.584 155.721 294.906 156.135C295.044 156.319 295.182 156.548 295.32 156.732L295.688 157.33L308.746 178.849C310.999 182.573 302.355 186.527 295.228 180.274L290.124 159.859L289.94 159.491C289.894 159.399 289.848 159.307 289.802 159.215C289.71 159.031 289.572 158.847 289.434 158.664C289.158 158.296 288.883 157.974 288.515 157.652C288.147 157.376 287.779 157.1 287.365 156.87L287.181 156.778L286.952 156.686L286.446 156.456L285.48 156.043L272.928 151.261C271.778 150.755 271.134 149.422 271.548 148.272C272.008 147.215 273.158 146.709 274.261 147.077Z"
        fill="#EDB0A0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M294.492 172.549L290.722 162.066L290.446 161.79C290.354 161.698 290.308 161.652 290.216 161.56C290.078 161.422 289.894 161.284 289.71 161.146C289.342 160.871 288.975 160.641 288.561 160.503C288.147 160.319 287.733 160.181 287.273 160.135L287.089 160.089L286.814 160.043L286.308 159.997L285.296 159.859L272.422 157.974C271.18 157.836 270.261 156.778 270.307 155.583C270.353 154.525 271.272 153.744 272.422 153.744L285.296 154.755H286.308H286.814H287.089H287.411C288.331 154.801 289.251 154.985 290.17 155.261C291.09 155.537 291.963 155.905 292.791 156.41C293.205 156.64 293.619 156.916 293.986 157.192C294.17 157.33 294.354 157.468 294.538 157.652L295.044 158.066L304.286 168.963C308.24 177.147 303.32 176.182 294.492 172.549Z"
        fill="#EDB0A0"
      />
      <path
        d="M274.261 147.077L287.043 150.985L288.055 151.307L288.561 151.491L288.791 151.583L289.113 151.721C290.032 152.042 290.86 152.502 291.687 153.054C292.515 153.606 293.251 154.249 293.94 154.985C294.262 155.353 294.584 155.721 294.906 156.135C295.044 156.319 295.182 156.548 295.32 156.732L295.688 157.33L308.746 178.849C310.999 182.573 302.355 186.527 295.228 180.274L290.124 159.859L289.94 159.491C289.894 159.399 289.848 159.307 289.802 159.215C289.71 159.031 289.572 158.847 289.434 158.664C289.158 158.296 288.883 157.974 288.515 157.652C288.147 157.376 287.779 157.1 287.365 156.87L287.181 156.778L286.952 156.686L286.446 156.456L285.48 156.043L272.928 151.261C271.778 150.755 271.134 149.422 271.548 148.272C272.008 147.215 273.158 146.709 274.261 147.077Z"
        fill="#EDB0A0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M299.32 173.009L293.343 163.629L293.021 163.399C292.929 163.353 292.837 163.262 292.745 163.216C292.561 163.124 292.377 162.986 292.193 162.94C291.779 162.756 291.366 162.618 290.952 162.526C290.538 162.434 290.078 162.388 289.618 162.434H289.434L289.159 162.48L288.653 162.526L287.641 162.618L278.215 163.629C276.974 163.767 275.824 162.94 275.64 161.79C275.457 160.733 276.192 159.767 277.296 159.537L286.538 157.652L287.549 157.422L288.055 157.33L288.285 157.284L288.607 157.238C289.526 157.1 290.446 157.054 291.412 157.1C292.377 157.146 293.297 157.33 294.216 157.606C294.676 157.744 295.136 157.928 295.55 158.112C295.78 158.204 296.01 158.296 296.193 158.434L296.791 158.756L303.872 172.274C309.39 179.4 308.746 174.619 299.32 173.009Z"
        fill="#EDB0A0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M283.273 159.583L296.239 159.261V175.446L291.549 176.688L283.273 159.583Z"
        fill="#EDB0A0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M311.827 166.066L300.608 166.71L298.493 184.688L309.252 187.815C313.62 183.309 314.448 173.653 311.827 166.066Z"
        fill="#EDB0A0"
      />
      <path
        d="M295.918 181.01C295.918 181.01 293.895 179.125 293.573 175.676C292.331 163.537 292.791 162.848 290.446 162.48C290.63 162.526 287.595 162.618 287.595 162.618L278.169 163.629C276.928 163.767 275.778 162.94 275.595 161.79C275.411 160.733 276.146 159.767 277.25 159.537L286.492 157.652C286.492 157.652 292.055 156.043 294.538 157.836C297.619 160.089 302.263 166.48 302.263 166.48"
        stroke="#010623"
        strokeWidth="0.919595"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M280.054 159.077C276.514 158.572 272.514 157.974 272.514 157.974C271.272 157.836 270.353 156.778 270.399 155.583C270.445 154.525 271.364 153.744 272.514 153.744L285.388 154.755H286.4H286.905H287.181H287.503C288.423 154.801 289.342 154.985 290.262 155.261C291.181 155.537 292.055 155.905 292.883 156.41C293.296 156.64 293.71 156.916 294.078 157.192C294.262 157.33 294.446 157.468 294.63 157.652"
        stroke="#010623"
        strokeWidth="0.919595"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M292.607 102.476C296.055 105.189 288.79 113.971 280.468 115.672C271.548 117.466 263.823 114.615 264.283 110.293C265.065 103.764 283.135 95.0274 292.607 102.476Z"
        fill="#010623"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M291.871 112.27L291.549 83.9463L270.169 84.3141L265.479 114.753C265.479 114.753 265.387 115.167 265.387 115.396C265.341 116.684 267.962 117.557 271.87 117.787L273.847 129.972L276.836 117.741C277.388 117.695 277.985 117.649 278.583 117.603C285.894 116.868 291.917 114.661 291.963 112.684C291.963 112.546 291.963 112.408 291.871 112.27Z"
        fill="#EDB0A0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M291.733 101.556C293.894 103.166 295.09 109.373 280.698 115.12C278.767 115.35 277.617 117.006 276.468 120.454L273.893 129.466L272.284 120.454C272.284 120.454 271.916 118.753 271.41 117.787C270.721 116.5 269.893 115.856 269.893 115.856C269.893 115.856 265.249 113.373 264.789 111.718C263.916 108.637 265.939 106.89 267.088 105.925C267.134 105.603 267.18 105.281 267.226 104.959C265.985 105.557 264.053 107.028 262.766 110.201C259.455 115.948 259.915 124.914 259.915 124.914L265.157 122.569L273.893 130.386L284.882 122.247C284.882 122.247 287.549 123.213 291.319 124.684C294.814 121.65 298.354 116.73 299.136 112.178L293.021 102.338L291.779 101.51L291.733 101.556Z"
        fill="#FEB400"
      />
      <path
        d="M291.917 101.786C292.653 102.476 293.067 103.304 293.067 104.361C293.067 108.178 287.595 113.189 280.744 115.075C279.227 115.488 277.985 116.5 277.342 117.925C276.192 120.362 274.767 124.592 273.939 130.018C273.157 124.316 272.56 116.592 268.789 115.029C268.1 114.753 267.41 114.431 266.858 113.925C265.801 112.959 265.019 111.718 265.019 110.523"
        stroke="#010623"
        strokeWidth="0.919595"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M259.869 124.914L272.606 121.88C272.606 121.88 271.64 117.558 270.49 115.764C269.341 114.017 264.099 117.144 259.869 124.914Z"
        fill="#010623"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M291.227 124.684L275.686 122.615C275.824 122.523 276.284 116.776 279.456 115.304C281.342 114.431 288.56 119.351 291.227 124.684Z"
        fill="#010623"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M266.076 111.074C285.342 104.453 284.79 89.7397 284.79 89.7397L268.789 93.2342L266.076 111.074Z"
        fill="#010623"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M265.295 98.476C266.582 99.7175 268.191 100.545 269.939 100.913C271.686 101.281 273.479 101.235 275.18 100.683C279.962 99.1198 286.675 95.3494 290.216 86.0155C297.067 88.1306 302.033 77.7852 297.894 74.9804C295.319 73.2791 291.825 75.1183 290.032 77.0495C289.94 77.1414 289.756 77.3713 289.71 77.4633C289.756 71.8538 296.101 65.9224 292.883 62.7498C275.87 46.0131 264.053 58.7955 261.524 61.2324C260.559 64.8648 260.237 68.7271 259.271 74.4286C257.202 86.2914 261.111 94.5678 265.295 98.476ZM289.986 77.0495C290.032 77.0035 290.032 77.0035 289.986 77.0495V77.0495Z"
        fill="#EDB0A0"
      />
      <path
        d="M268.191 87.119C268.191 87.119 272.927 87.8547 276.008 84.5901"
        stroke="#010623"
        strokeWidth="0.919595"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M267.18 66.796C264.605 65.9683 261.065 66.9339 260.329 67.8995"
        stroke="#010623"
        strokeWidth="0.919595"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M282.813 68.2674C281.066 66.9799 277.525 65.6925 273.617 66.796"
        stroke="#010623"
        strokeWidth="0.919595"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M268.881 71.7158C268.881 71.7158 268.927 74.1068 266.858 78.061C264.973 81.6474 267.272 83.4407 271.364 82.2912"
        stroke="#010623"
        strokeWidth="0.919595"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M277.985 71.3939C277.295 71.3479 276.652 72.3135 276.606 73.509C276.56 74.7044 277.111 75.67 277.801 75.716C278.491 75.762 279.135 74.7964 279.181 73.6009C279.227 72.4055 278.675 71.4399 277.985 71.3939Z"
        fill="#010623"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M264.099 70.7963C263.41 70.7503 262.766 71.7159 262.72 72.9113C262.674 74.1068 263.226 75.0724 263.915 75.1183C264.605 75.1643 265.249 74.1988 265.295 73.0033C265.341 71.8078 264.789 70.8422 264.099 70.7963Z"
        fill="#010623"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M258.214 61.6922C258.996 74.0148 260.881 63.7613 260.881 63.7613C263.088 65.3706 272.054 62.6578 279.319 59.0254C286.537 55.393 291.043 54.7952 290.354 64.1291C289.894 70.4284 301.021 52.2204 294.308 52.5422C296.331 39.5759 280.238 47.1166 280.238 47.1166C280.238 47.1166 276.1 41.0933 269.801 41.0013C263.915 40.9094 259.317 45.6453 260.099 51.2548C255.731 51.1628 252.328 52.0824 258.214 61.6922Z"
        fill="#FEB400"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M262.076 63.8992C264.559 64.8188 270.26 63.4394 279.226 59.7151C286.721 56.6344 289.756 59.3932 289.388 68.7271C289.296 70.8882 288.928 76.1299 288.698 79.1185C289.71 76.9575 292.101 74.5665 296.239 74.2447C294.492 68.4512 304.331 52.0825 294.262 52.5422C294.538 50.795 294.584 49.7835 294.4 48.3581C294.078 47.3465 291.089 42.0589 282.491 52.8181C276.376 60.4967 267.456 64.6349 262.076 63.8992ZM258.214 61.6922C257.984 67.9455 259.501 72.8193 259.501 72.8193L261.386 63.8992C259.501 62.9797 258.627 62.198 258.214 61.6922Z"
        fill="#F8444F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M228.189 174.067C228.189 164.273 220.235 156.365 210.441 156.365C200.647 156.365 192.693 164.319 192.693 174.067C192.693 182.987 199.268 190.344 207.82 191.585L216.418 193.47L216.556 190.665C223.407 188.182 228.189 181.699 228.189 174.067Z"
        fill="#F8444F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M219.821 164.687C227.867 164.687 234.442 171.216 234.442 179.309C234.442 187.401 227.913 193.93 219.821 193.93C211.774 193.93 205.199 187.401 205.199 179.309C205.199 171.216 211.774 164.687 219.821 164.687Z"
        fill="#F8444F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M223.775 167.998C231.224 167.998 237.247 174.021 237.247 181.47C237.247 188.918 231.224 194.942 223.775 194.942C216.327 194.942 210.303 188.918 210.303 181.47C210.303 174.021 216.327 167.998 223.775 167.998Z"
        fill="#010623"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M211.453 181.516C211.453 188.229 216.878 193.654 223.591 193.654C229.937 193.654 235.086 188.826 235.684 182.665L236.282 177.056C236.374 176.504 236.466 175.952 236.466 175.4C236.466 170.71 232.649 166.894 227.959 166.894C226.35 166.894 224.833 167.354 223.591 168.136L218.028 170.664L218.12 170.71C214.165 172.733 211.453 176.78 211.453 181.516Z"
        fill="#EDB0A0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M227.959 166.894C232.649 166.894 236.466 170.71 236.466 175.4C236.466 175.952 236.42 176.504 236.282 177.056L235.684 182.665C235.132 188.826 229.937 193.654 223.591 193.654C223.407 193.654 223.178 193.654 222.994 193.608C218.58 187.401 218.166 175.446 221.43 169.101L223.545 168.136C224.833 167.354 226.35 166.894 227.959 166.894Z"
        fill="#010623"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M222.258 139.214H259.685C261.341 139.214 263.18 140.548 263.823 142.203L282.169 190.39C282.813 192.045 281.985 193.378 280.33 193.378H242.903C241.247 193.378 239.408 192.045 238.765 190.39L220.373 142.157C219.775 140.548 220.603 139.214 222.258 139.214Z"
        fill="#010623"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M240.236 142.341C241.018 142.341 242.075 143.352 242.535 144.64C242.995 145.927 242.765 146.939 241.983 146.939C241.202 146.939 240.144 145.927 239.684 144.64C239.178 143.398 239.408 142.341 240.236 142.341Z"
        fill="#00C753"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M225.293 181.148C228.189 188.826 233.799 188.136 236.466 185.7C235.178 182.251 232.833 177.837 237.063 175.4C231.086 178.527 228.051 162.204 222.81 162.48C219.131 162.71 220.649 168.917 225.293 181.148Z"
        fill="#EDB0A0"
      />
      <path
        d="M239.408 150.801L226.856 157.928L225.89 158.526L225.385 158.801L225.155 158.939L224.833 159.123C223.959 159.675 223.683 160.043 222.948 160.824C218.442 164.549 222.488 173.331 227.867 164.135L228.005 163.997L228.235 163.813L228.695 163.491L229.615 162.802L241.661 154.847C242.765 154.019 243.087 152.502 242.397 151.399C241.799 150.387 240.466 150.157 239.408 150.801Z"
        fill="#EDB0A0"
      />
      <path
        d="M244.558 157.514L231.04 162.434L229.983 162.848L229.431 163.032L229.155 163.124L228.833 163.262C227.868 163.675 226.948 163.721 226.12 164.365C222.396 167.124 228.373 169.699 228.373 169.699L232.787 167.4L246.214 161.882C247.455 161.239 248.007 159.767 247.501 158.618C246.949 157.514 245.708 157.054 244.558 157.514Z"
        fill="#EDB0A0"
      />
      <path
        d="M244.558 162.802L231.04 167.722L229.983 168.135C229.983 168.135 227.178 168.963 226.58 170.296C226.58 175.17 231.914 173.561 231.914 173.561L232.925 173.055L246.121 167.216C247.363 166.572 247.915 165.101 247.409 163.951C246.949 162.802 245.708 162.342 244.558 162.802Z"
        fill="#EDB0A0"
      />
      <path
        d="M242.443 168.917L229.155 174.205L229.799 179.676L230.81 179.171L244.006 173.331C245.248 172.687 245.799 171.216 245.294 170.067C244.88 168.963 243.592 168.503 242.443 168.917Z"
        fill="#EDB0A0"
      />
      <path
        d="M243.96 173.331C245.202 172.687 245.754 171.216 245.248 170.067C244.788 168.963 243.501 168.503 242.351 168.963L229.477 173.883L227.729 175.032"
        stroke="#010623"
        strokeWidth="0.919595"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M246.121 161.882C247.363 161.239 247.914 159.767 247.409 158.618C246.949 157.514 245.661 157.054 244.512 157.514L230.994 162.434L228.741 163.767C228.741 163.767 225.936 164.595 225.338 165.928"
        stroke="#010623"
        strokeWidth="0.919595"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M246.121 167.17C247.363 166.526 247.915 165.055 247.409 163.905C246.949 162.802 245.662 162.342 244.512 162.802L230.994 167.722L230.58 168.135C230.58 168.135 227.224 169.055 226.626 170.388"
        stroke="#010623"
        strokeWidth="0.919595"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M362.542 299.775C362.542 299.775 365.301 379.734 281.709 408.701"
        stroke="#00C753"
        strokeWidth="0.919595"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M279.411 423.829C279.273 423.323 279.043 422.495 278.813 421.3C277.755 423.323 278.675 424.059 279.227 424.289C279.273 424.105 279.365 423.967 279.411 423.829ZM305.021 362.63C305.021 362.63 296.837 361.342 302.447 352.836C322.954 321.754 315.827 307.868 311.045 310.489C309.987 311.041 307.872 313.569 307.872 313.569C279.871 350.123 276.238 409.345 278.813 421.254C279.411 420.104 285.986 428.381 288.285 426.266C351.553 378.447 305.021 362.63 305.021 362.63Z"
        fill="#010623"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M341.943 390.31C341.943 390.31 362.312 416.288 320.103 412.702C286.078 409.851 290.768 387.229 341.943 390.31Z"
        fill="#010623"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M236.098 437.623L241.523 477.487H241.615C242.581 479.051 253.524 480.568 266.95 480.982C281.02 481.396 292.423 480.43 292.423 478.775C292.423 478.683 292.377 478.591 292.285 478.499H292.515L297.573 439.784C297.711 439.646 297.803 439.508 297.803 439.37C297.848 437.393 284.055 435.37 266.996 434.864C249.938 434.358 236.144 435.554 236.098 437.531C236.144 437.577 236.144 437.577 236.098 437.623Z"
        fill="#CABEC4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M267.548 429.944C252.191 429.484 237.983 435.83 237.983 437.623C237.983 439.416 250.397 441.209 265.709 441.669C281.066 442.129 293.526 441.071 293.526 439.278C293.526 437.485 282.859 430.404 267.548 429.944Z"
        fill="#010623"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M273.847 431.093C287.365 422.311 272.927 422.081 273.571 409.345C275.686 367.503 252.282 308.603 236.052 321.156C219.821 333.754 249.478 384.378 249.478 384.378C232.787 379.826 201.107 386.355 273.847 431.093Z"
        fill="#00C753"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M267.961 427.921C267.961 427.921 279.778 403.506 322.493 387.091C347.552 377.481 360.427 376.838 369.025 377.205C382.681 377.757 381.439 388.47 355.875 396.885C337.943 402.77 325.482 396.747 325.482 396.747C325.482 396.747 340.15 426.358 320.976 437.163C297.388 450.405 267.961 427.921 267.961 427.921Z"
        fill="#00C753"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M298.492 352.1C298.492 352.1 316.378 343.594 314.769 364.009C313.666 378.263 289.618 405.207 289.618 405.207C289.618 405.207 308.148 402.126 297.021 418.403C285.296 435.6 273.433 426.818 273.065 429.99C273.065 429.99 259.272 395.597 287.089 339.962C291.917 330.306 302.998 314.581 311.367 310.351C295.871 323.823 298.492 352.1 298.492 352.1Z"
        fill="#00C753"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M218.395 408.472C182.439 411 191.037 461.808 243.868 438.91C268.697 428.151 281.388 431.186 285.434 432.243C218.074 305.109 156.507 377.619 218.395 408.472Z"
        fill="#00C753"
      />
    </svg>
  );
};

export default OnboardingIllustration;
