import * as React from "react";
import ChevronRightIcon from "../icons/ChevronRight";

interface LinkProps {
  href: string;
  color?: string;
}

const Link: React.FC<LinkProps> = ({ href, color, children }) => {
  return (
    <a
      href={href}
      className={`inline-flex items-center ${color ? color : "text-secondary"} hover:underline hover:text-secondary-alt`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <span className="mr-1.5">{children}</span>
      <ChevronRightIcon size={14} />
    </a>
  );
};

export default Link;
