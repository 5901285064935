import * as React from "react";

const InternalTraining: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.33301 4.66665L10.8497 2.77081C11.0713 2.49431 11.4062 2.33331 11.7608 2.33331H16.2397C16.5943 2.33331 16.9292 2.49431 17.1508 2.77081L18.6663 4.66665V5.83331C18.6663 6.47731 18.1437 6.99998 17.4997 6.99998H10.4997C9.85567 6.99998 9.33301 6.47731 9.33301 5.83331V4.66665Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33317 4.66663H6.99984C5.71067 4.66663 4.6665 5.71079 4.6665 6.99996V22.1666C4.6665 23.4558 5.71067 24.5 6.99984 24.5H20.9998C22.289 24.5 23.3332 23.4558 23.3332 22.1666V6.99996C23.3332 5.71079 22.289 4.66663 20.9998 4.66663H18.6665"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3332 14.5833L13.4165 17.5L11.6665 15.75"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default InternalTraining;
