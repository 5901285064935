import * as React from "react";

const Conferences: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 6.5C22 5.96957 21.7893 5.46086 21.4142 5.08579C21.0391 4.71071 20.5304 4.5 20 4.5H18C17.8728 4.50537 17.7506 4.55096 17.651 4.63021C17.5514 4.70946 17.4795 4.81828 17.4457 4.941C17.3467 5.35077 17.1127 5.71533 16.7815 5.97606C16.4502 6.2368 16.0409 6.37856 15.6194 6.37855C15.1978 6.37855 14.7885 6.23677 14.4572 5.97602C14.126 5.71527 13.8921 5.3507 13.7931 4.94093C13.7592 4.81823 13.6874 4.70942 13.5877 4.63018C13.4881 4.55095 13.366 4.50536 13.2388 4.5H4C3.46957 4.5 2.96086 4.71071 2.58579 5.08579C2.21071 5.46086 2 5.96957 2 6.5V17.5C2 18.0304 2.21071 18.5391 2.58579 18.9142C2.96086 19.2893 3.46957 19.5 4 19.5H13.2388C13.366 19.4946 13.4881 19.4491 13.5877 19.3698C13.6873 19.2906 13.7592 19.1818 13.7931 19.0591C13.8921 18.6493 14.126 18.2847 14.4572 18.024C14.7885 17.7633 15.1978 17.6215 15.6194 17.6215C16.0409 17.6215 16.4502 17.7632 16.7815 18.024C17.1127 18.2847 17.3467 18.6493 17.4457 19.059C17.4795 19.1817 17.5514 19.2906 17.651 19.3698C17.7506 19.449 17.8728 19.4946 18 19.5H20C20.5304 19.5 21.0391 19.2893 21.4142 18.9142C21.7893 18.5391 22 18.0304 22 17.5V6.5Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 15.25V14.75"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 12.25V11.75"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 9.25V8.75"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Conferences;
