import * as React from "react";

interface LoadingIconProps {
  size?: "normal" | "small" | "tiny";
  color?: "blue" | "white" | "gray";
}

const animationDelays: { [key: number]: string } = {
  0: "animation-delay-0",
  1: "animation-delay-1",
  2: "animation-delay-2",
};

const sizes = {
  normal: "w-4 h-4 mx-2.5",
  small: "w-2 h-2 mx-1.5",
  tiny: "w-1 h-1 mx-0.5",
};

const colors = {
  blue: "bg-secondary",
  white: "bg-white",
  gray: "bg-gray-700",
};

const LoadingIcon: React.FC<LoadingIconProps> = ({
  size = "normal",
  color = "blue",
}) => {
  const baseStyles = `${sizes[size]} ${colors[color]}`;
  return (
    <div className="flex items-center justify-center py-2">
      {Array.from({ length: 3 }).map((val, index) => (
        <span
          key={`circle-${index}`}
          className={`rounded-full animate-ping ${animationDelays[index]} ${baseStyles}`}
        />
      ))}
    </div>
  );
};

export default LoadingIcon;
