import * as React from "react";
import { ROOT_PATH } from "../constants";

const Logo: React.FC = () => {
  return (
    <a href={ROOT_PATH}>
      <svg
        width="137"
        height="28"
        viewBox="0 0 137 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2565_23678)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.0965 6.46782L12.202 -0.000183105L2.055 5.96032C0.7825 6.70682 0 8.07232 0 9.54732V12.1853V13.5613V17.0778L12.202 9.20482L25.127 17.0778V14.1753V12.1853V10.0388C25.127 8.57482 24.3555 7.21682 23.0965 6.46782Z"
            fill="#009FDD"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.5863 18.9841L12.1883 14.6061L4.99478 18.9841C3.92728 19.6096 3.89278 21.1601 4.93128 21.8321L12.1883 26.3511L19.5993 21.8321C20.6698 21.1816 20.6633 19.6251 19.5863 18.9841Z"
            fill="#009FDD"
          />
          <path
            d="M82.1331 7.01664C80.464 7.01664 79.0126 7.76652 78.2385 9.19371V7.01664H75.8438V19.7162H78.2627V13.4027C78.2627 10.5967 79.6657 9.2179 82.1331 9.2179H83.1007V7.01664H82.1331Z"
            fill="currentColor"
          />
          <path
            d="M89.2875 6.82312C86.0703 6.82312 83.9416 8.46802 83.6755 11.0563H86.1913C86.3848 9.72588 87.4733 8.90343 89.215 8.90343C91.1018 8.90343 92.1903 9.87102 92.1903 11.7578V12.2658H88.2716C85.1995 12.2658 83.2643 13.7897 83.2643 16.0636C83.2643 18.3132 85.1269 19.8614 87.812 19.8614C89.6988 19.8614 91.3195 19.1115 92.1903 17.8294V19.7162H94.5609V11.782C94.5609 8.63735 92.6499 6.82312 89.2875 6.82312ZM88.2716 17.8778C86.6751 17.8778 85.7075 17.1279 85.7075 15.9668C85.7075 14.7815 86.6992 14.0558 88.5135 14.0558H92.1903V14.3703C92.1903 16.4022 90.497 17.8778 88.2716 17.8778Z"
            fill="currentColor"
          />
          <path
            d="M103.64 6.82312C101.826 6.82312 100.278 7.573 99.3828 8.87924V7.01664H96.988V19.7162H99.407V12.8464C99.407 10.379 100.931 9.02438 102.939 9.02438C104.729 9.02438 105.866 10.1371 105.866 12.3867V19.7162H108.285V11.7578C108.285 8.63735 106.47 6.82312 103.64 6.82312Z"
            fill="currentColor"
          />
          <path
            d="M120.269 8.90343C119.422 7.64557 117.801 6.82312 115.963 6.82312C112.576 6.82312 109.94 9.50818 109.94 13.185C109.94 16.8618 112.576 19.5469 115.963 19.5469C117.801 19.5469 119.422 18.7244 120.269 17.4666V19.2082C120.269 21.3853 118.769 22.8367 116.374 22.8367C114.463 22.8367 113.012 21.8933 112.649 20.4903H110.109C110.448 23.127 112.818 24.8928 116.277 24.8928C120.244 24.8928 122.688 22.5706 122.688 19.2082V7.01664H120.269V8.90343ZM116.35 17.4424C114.028 17.4424 112.407 15.6765 112.407 13.185C112.407 10.6935 114.028 8.95181 116.35 8.95181C118.696 8.95181 120.317 10.6935 120.317 13.185C120.317 15.6765 118.696 17.4424 116.35 17.4424Z"
            fill="currentColor"
          />
          <path
            d="M136.999 12.9915C136.999 9.2179 134.411 6.82312 130.951 6.82312C127.275 6.82312 124.444 9.53236 124.444 13.4753C124.444 17.3214 127.105 19.9097 130.903 19.9097C133.806 19.9097 136.128 18.41 136.83 15.9184H134.483C133.903 17.1279 132.596 17.8536 130.976 17.8536C128.823 17.8536 127.081 16.5232 126.888 13.9107H136.95C136.975 13.6688 136.999 13.3543 136.999 12.9915ZM130.976 8.85505C132.862 8.85505 134.265 9.9194 134.556 12.0481H127.009C127.468 9.99197 129.016 8.85505 130.976 8.85505Z"
            fill="currentColor"
          />
          <path
            d="M38.3619 19.9097C41.9178 19.9097 44.6996 17.1763 44.6996 13.4269C44.6996 9.6533 41.9178 6.91986 38.3619 6.91986C34.7818 6.91986 32 9.6533 32 13.4269C32 17.1763 34.7818 19.9097 38.3619 19.9097ZM38.3619 18.0955C35.8462 18.0955 34.0803 16.1845 34.0803 13.4269C34.0803 10.6451 35.8462 8.73409 38.3619 8.73409C40.8534 8.73409 42.6193 10.6451 42.6193 13.4269C42.6193 16.1845 40.8534 18.0955 38.3619 18.0955Z"
            fill="#009FDD"
          />
          <path
            d="M52.9331 6.91986C51.0947 6.91986 49.5708 7.66974 48.6516 8.97598V7.11338H46.6196V19.7162H48.6516V12.7738C48.6516 10.2097 50.2965 8.78247 52.4252 8.78247C54.3361 8.78247 55.5698 9.94357 55.5698 12.3142V19.7162H57.6018V11.8304C57.6018 8.73409 55.7875 6.91986 52.9331 6.91986Z"
            fill="#009FDD"
          />
          <path
            d="M71.6701 13.0399C71.6701 9.29045 69.1544 6.91986 65.7437 6.91986C62.1394 6.91986 59.3334 9.60492 59.3334 13.5236C59.3334 17.3214 61.9459 19.9097 65.6953 19.9097C68.5013 19.9097 70.7993 18.4583 71.5008 16.0636H69.5173C68.8641 17.3698 67.4611 18.1439 65.7679 18.1439C63.4698 18.1439 61.5588 16.6683 61.4137 13.8623H71.6218C71.6459 13.6688 71.6701 13.3785 71.6701 13.0399ZM65.7679 8.66152C67.824 8.66152 69.3237 9.84681 69.614 12.2174H61.5105C61.9459 9.91938 63.6633 8.66152 65.7679 8.66152Z"
            fill="#009FDD"
          />
        </g>
        <defs>
          <clipPath id="clip0_2565_23678">
            <rect width="137" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </a>
  );
};

export default Logo;
