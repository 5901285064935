import * as React from "react";

const Publications: React.FC = () => {
  return (
    <svg
      width="28"
      height="24"
      viewBox="0 0 28 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2149_15118)">
        <path
          d="M11.667 14.015C12.9562 12.4598 15.0445 12.4598 16.3337 14.015"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.3005 10.7001C12.1229 12.5225 12.1229 15.4773 10.3005 17.2997C8.47803 19.1222 5.52326 19.1222 3.70082 17.2997C1.87837 15.4773 1.87837 12.5225 3.70082 10.7001C5.52327 8.87764 8.47804 8.87764 10.3005 10.7001"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.3005 10.7001C26.1229 12.5225 26.1229 15.4773 24.3005 17.2997C22.478 19.1222 19.5233 19.1222 17.7008 17.2997C15.8784 15.4773 15.8784 12.5225 17.7008 10.7001C19.5233 8.87764 22.478 8.87764 24.3005 10.7001"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.33366 14H1.16699"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.8337 14H25.667"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2149_15118">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Publications;
