import * as React from "react";

const Trending: React.FC = () => {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.83211 10.8345C7.68344 11.9832 5.7494 11.6682 5.02282 10.2171L4.16604 8.4993C2.67298 9.9924 1.83301 12.0189 1.83301 14.1336V14.3331C1.83301 18.8439 5.489 22.5 9.99967 22.5C14.5103 22.5 18.1663 18.8439 18.1663 14.3331C18.1663 12.1197 17.1647 10.026 15.4406 8.6379L14.1744 7.6173C12.8934 6.5841 11.9967 5.1498 11.6313 3.5454L11.1651 1.5C8.59955 4.06567 8.15531 7.43586 8.83211 10.8345Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Trending;
