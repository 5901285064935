import * as React from "react";

const Design: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
    >
      <path
        d="M16.8506 3.57663C16.1981 2.92413 15.1522 2.88997 14.4581 3.49663L7.76055 9.35663C7.03472 9.99163 6.99805 11.1075 7.67972 11.7891L8.63722 12.7466C9.31889 13.4283 10.4356 13.3908 11.0697 12.6658L16.9297 5.9683C17.5372 5.27497 17.5022 4.22913 16.8506 3.57663V3.57663Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.05859 8.22498L12.2086 11.375"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.99893 16.9358H6.08476C8.57226 16.9358 9.81809 13.9283 8.05893 12.1692C6.76559 10.8758 4.56893 11.3517 3.92726 13.0642L2.65893 16.4458C2.57059 16.6833 2.74559 16.9358 2.99893 16.9358V16.9358Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Design;
