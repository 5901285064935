import * as React from "react";
import CloseIcon from "../icons/Close";
import { createCtx } from "../utils";

type SnackbarMessageVariant = "info" | "error";
type SnackbarMessage = {
  message: string;
  variant: SnackbarMessageVariant;
} | null;

type SetSnackbarMessage = (
  message: string,
  variant?: SnackbarMessageVariant,
) => void;

const [useSnackbarContext, SnackbarContextProvider] =
  createCtx<SetSnackbarMessage>();

const SnackbarProvider: React.FC = ({ children }) => {
  const [snackbarMessage, updateSnackbarMessage] =
    React.useState<SnackbarMessage>(null);

  const handleClose = React.useCallback(() => {
    updateSnackbarMessage(null);
  }, []);

  const setSnackbarMessage = React.useCallback(
    (newMessage: string, variant: SnackbarMessageVariant = "info") => {
      updateSnackbarMessage({ message: newMessage, variant: variant });
    },
    [],
  );

  return (
    <SnackbarContextProvider value={setSnackbarMessage}>
      {children}
      <Snackbar snackbarMessage={snackbarMessage} onClose={handleClose} />
    </SnackbarContextProvider>
  );
};

interface SnackbarProps {
  snackbarMessage: SnackbarMessage;
  onClose: () => void;
}

const SNACKBAR_TIMEOUT = 6000;

const Snackbar: React.FC<SnackbarProps> = ({ snackbarMessage, onClose }) => {
  const timeout = React.useRef<number>();

  React.useEffect(() => {
    if (snackbarMessage && onClose) {
      timeout.current = window.setTimeout(() => {
        onClose();
      }, SNACKBAR_TIMEOUT);
      return () => {
        window.clearTimeout(timeout.current);
      };
    }
  }, [snackbarMessage, onClose]);

  if (!snackbarMessage) {
    return null;
  }

  return (
    <div className={`fixed bottom-6 left-12 right-12 z-50`}>
      <div
        className={`inline-flex max-w-full items-center text-white shadow-md px-4 py-3 rounded ${
          snackbarMessage.variant === "error" ? "bg-red" : "bg-secondary"
        }`}
      >
        <span className="mr-8 block truncate">{snackbarMessage.message}</span>
        <button
          onClick={onClose}
          className="flex opacity-80 hover:opacity-100 flex-shrink-0"
        >
          <CloseIcon />
        </button>
      </div>
    </div>
  );
};

const useSnackbar = () => {
  const setSnackbarMessage = useSnackbarContext();
  return { setSnackbarMessage };
};

export { useSnackbar, SnackbarProvider };
